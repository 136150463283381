import React, { useEffect, useRef, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import { PageProfil } from '../page-profil/PageProfil';
import { listeProfiles } from './data';

import './NotreEquipe.scoped.scss';
import { getNotreEquipeContent } from '../../api/api';
import { bool } from 'prop-types';

const duration = 1500;

const defaultStyle = {
  transition: `all ${duration}ms ease-in-out`,
  opacity: 0,
  transform: 'translate(-100px, 0vh)'
};

const transitionStyles = {
  entering: { opacity: 0, transform: 'translate(0, 0vh)' },
  entered: { opacity: 1, transform: 'translate(0, 0vh)' },
  exiting: { opacity: 0 },
  exited: { opacity: 0 }
};

NotreEquipe.propTypes = {
  isDesktop: bool
};

export function NotreEquipe({ isDesktop }) {
  document.body.classList.remove('hidden-scrollbar-y');
  const { id } = useParams();

  const [contentData, setContentData] = useState(null);
  const [hasDataBeenFetched, setHasDataBeenFetched] = useState(false);
  const idProfil = Number(id) || 0;
  const [scrollLevel, setScrollLevel] = useState(0);
  const navigate = useNavigate();
  const handleMoveBackward = () => {
    if (idProfil >= 2) {
      navigate(`/notre-equipe/${idProfil - 1}`);
      window.focus();
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  };
  const handleMoveForward = () => {
    if (idProfil <= 9) {
      navigate(`/notre-equipe/${idProfil + 1}`);
      window.focus();
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  };
  const handleClickProfil = (idP) => {
    window.focus();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    navigate(`/notre-equipe/${idP}`);
  };
  const nodeRef = useRef(null);
  const nodeRefs = useRef();
  useEffect(() => {
    if (!hasDataBeenFetched) {
      getNotreEquipeContent().then((data) => {
        setContentData(data?.data);
        setHasDataBeenFetched(true);
      });
    }
    function handleScroll() {
      setScrollLevel(window.scrollY);
    }

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [id, contentData]);

  const scrollParagraph =
    scrollLevel < 440 && isDesktop && window.innerWidth > 1300 ? 220 - scrollLevel / 2 : 0;
  const scrollProfiles =
    scrollLevel < 1000 && isDesktop && window.innerWidth > 1300 ? 70 - scrollLevel / 10 : 0;
  return (
    <>
      <div>
        <div className="two-pages-container">
          <Transition
            nodeRef={nodeRef}
            in={idProfil === 0}
            timeout={{ duration }}
            unmountOnExit
            mountOnEnter>
            {(state) => (
              <div
                className="notre-equipe-page-container"
                ref={nodeRef}
                style={{
                  ...defaultStyle,
                  ...transitionStyles[state]
                }}>
                <div
                  style={{
                    backgroundImage: `url(${process.env.REACT_APP_API_URI}/uploads/notre_equipe_6ee6e17cc8.gif)`,
                    backgroundSize: 'cover',
                    backgroundPositionX: 'center'
                  }}
                  className="notre-equipe-titre-principal">
                  <div className="notre-equipe-titre-principal-text">
                    <div>{contentData?.title1?.title11}</div>
                    <div>{contentData?.title1?.title12}</div>
                  </div>
                </div>
                <div className="notre-equipe-sec2-container">
                  <div className="notre-equipe-titre-2">
                    <div className="notre-equipe-titre-2-text">
                      <div>{contentData?.section2?.title21}</div>
                      <div>{contentData?.section2?.title22}</div>
                      <div>{contentData?.section2?.title23}</div>
                    </div>
                    <div className="divider-notre-equipe" />
                  </div>
                  <div className="notre-equipe-paragraph-container">
                    {contentData?.section2?.paragraph?.map((p, index) => (
                      <div
                        style={{
                          transform:
                            'translate(' +
                            (index % 2 === 1 ? -scrollParagraph : scrollParagraph) +
                            'px, 0vh)',
                          transitionDuration: '1s'
                        }}
                        key={index}
                        className="notre-equipe-paragraph">
                        {p}
                      </div>
                    ))}
                  </div>
                  <div className="notre-equipe-profiles-background">
                    <div className="notre-equipe-profiles">
                      {contentData?.section2?.profiles?.map((profil, index) => (
                        <div
                          key={profil?.id}
                          onClick={() => handleClickProfil(profil?.id)}
                          style={{
                            transform:
                              'translate(0,' +
                              (index % 2 === 1 ? scrollProfiles : -scrollProfiles) +
                              'px)',
                            transitionDuration: '0.5s',
                            
                          }}
                          className="notre-equipe-profil">
                          <div className="notre-equipe-profil-container">
                            <div className="notre-equipe-profil-img-container">
                              <img
                                className="notre-equipe-profil-img"
                                src={
                                  listeProfiles.find((profilImg) => profilImg.id === profil.id)
                                    .pathImg
                                }
                                alt=""
                              />
                            </div>
                            <div className="ne-profil-name">{profil?.nom}</div>
                            <div className="ne-profil-poste">{profil?.poste}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Transition>
          {contentData?.section2?.profiles.map((profil, index) => (
            <Transition
              nodeRef={nodeRefs}
              in={idProfil === profil.id}
              timeout={duration}
              unmountOnExit
              mountOnEnter
              enter={false}
              exit={false}
              key={index}>
              {(state) => (
                <div
                  ref={nodeRefs}
                  style={{
                    ...defaultStyle,
                    ...transitionStyles[state]
                  }}
                  key={profil.name}>
                  <PageProfil
                    handleClickProfil={handleClickProfil}
                    profil={{
                      ...profil,
                      pathImg: listeProfiles.find((profilImg) => profilImg.id === profil.id).pathImg
                    }}
                    handleMoveForward={handleMoveForward}
                    handleMoveBackward={handleMoveBackward}
                    isDesktop={isDesktop}
                  />
                </div>
              )}
            </Transition>
          ))}
        </div>
      </div>
    </>
  );
}
