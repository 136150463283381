import axios from 'axios';

export const getHomeDataContent = () =>
  fetch(`${process.env.REACT_APP_API_URI}/api/home?populate=*`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Origin: ''
    }
  }).then((response) => response.json());

export const getNotreEquipeContent = () =>
  fetch(`${process.env.REACT_APP_API_URI}/api/notre-equipe?populate=*`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Origin: ''
    }
  }).then((response) => response.json());

export const getGammeOPCVMContent = () =>
  fetch(`${process.env.REACT_APP_API_URI}/api/gamme-opcvm?populate=*`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Origin: ''
    }
  }).then((response) => response.json());

export const getGammePerformancesContent = () =>
  fetch(`${process.env.REACT_APP_API_URI}/api/gamme-performance?populate=*`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Origin: ''
    }
  }).then((response) => response.json());

export const getExpertiseContent = () =>
  fetch(`${process.env.REACT_APP_API_URI}/api/notre-expertise?populate=*`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Origin: ''
    }
  }).then((response) => response.json());

export const getAfgContent = (id) =>
  fetch(`${process.env.REACT_APP_API_URI}/api/gamme-monetaires/${id}?populate=*`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Origin: ''
    }
  }).then((response) => response.json());


export const getExcelGraphData = (sheetLabel) =>
  fetch(
    `${process.env.REACT_APP_API_URI}/api/vl-performances-graph?fileName=historique_Performances_95e008649a.xlsx&sheetName=` +
      sheetLabel,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Origin: ''
      }
    }
  ).then((response) => response.json());


// export const getExcelGraphData = (sheetLabel) => {
//   return fetch(
//     `${process.env.REACT_APP_API_URI}/api/vl-performances-graph?fileName=historique_Performances_95e008649a.xlsx&sheetName=` +
//       sheetLabel,
//     {
//       headers: {
//         Accept: 'application/json',
//         'Content-Type': 'application/json',
//         Origin: ''
//       }
//     }
//   )
//     .then((response) => response.json())
//     .then((data) => {
//     console.log("je ss la");
//       // Vérifier si 'data' est un tableau avant d'appeler filter
//       if (Array.isArray(data)) {
//         return data.filter(/* Votre condition de filtre ici */);
//       } else {
//         // Gérez le cas où 'data' n'est pas un tableau (peut-être retourner un tableau vide ou null)
//         return null;
//       }
//     });
// };

// export const getExcelTableauData = (sheetLabels) =>
//   fetch(
//     `${process.env.REACT_APP_API_URI}/api/vl-performances-tableau?fileName=historique_Performances_95e008649a.xlsx&sheetNames=` +
//       sheetLabels,
//     {
//       headers: {
//         Accept: 'application/json',
//         'Content-Type': 'application/json',
//         Origin: ''
//       }
//     }
//   ).then((response) => response.json());


export const getExcelTableauData = (sheetLabels) => {
  return fetch(
    `${process.env.REACT_APP_API_URI}/api/vl-performances-tableau?fileName=historique_Performances_95e008649a.xlsx&sheetNames=` +
      sheetLabels,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Origin: ''
      }
    }
  )
    .then((response) => response.json())
    .then((data) => {
      // Vérifier si 'data' est un tableau avant d'appeler filter
      if (Array.isArray(data)) {
        // Vous devez spécifier une fonction de rappel pour filter, sinon, il retournera le tableau non filtré
        return data.filter((item) => item !== undefined); // Exemple de filtre, ajustez-le selon vos besoins
      } else {
        // Gérez le cas où 'data' n'est pas un tableau (peut-être retourner un tableau vide ou null)
        return null;
      }
    });
};



export const getPublications = async () => {
  try {
    const token = sessionStorage.getItem('token');
    let headers = {};
    if (token) {
      headers = {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      };
    }
    const response = await axios.get('/api/publications/docs', {
      headers,
      baseURL: process.env.REACT_APP_API_URI
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

export const downloadDoc = async (url) => {
  try {
    const token = sessionStorage.getItem('token');
    let headers = {};
    if (token) {
      headers = {
        Authorization: `Bearer ${token}`,
        // 'Content-Type': 'application/pdf'
      };
    }
    return axios.get(url, {
      headers,
      responseType: 'blob',
      baseURL: process.env.REACT_APP_API_URI
    });

  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

export const downloadDocEspaceClient = async (url) => {
  try {
    const token = sessionStorage.getItem('token');
    let headers = {};
    if (token) {
      headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/pdf'
      };
    }
    return axios.get(url, {
      headers,
      // baseURL: 'https://www.afgassetmanagement.com/admin'
      baseURL: process.env.REACT_APP_API_URI

    });

  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

export const getDocsEspaceClientContent = async () => {
  try {
    const token = sessionStorage.getItem('token');
    let headers = {};
    if (token) {
      headers = {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      };
    }
    const response = await axios.get('/api/espace-client/user-docs?populate=*', {
      headers,
      baseURL: process.env.REACT_APP_API_URI
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

export const getVLContent = async () => {
  try {
    const token = sessionStorage.getItem('token');
    let headers = {};
    if (token) {
      headers = {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      };
    }
    const response = await axios.get('/api/nos-publication?populate=*', {
      headers,
      baseURL: process.env.REACT_APP_API_URI
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

export const getNosPublicationsPdfsContent = () =>
  fetch(`${process.env.REACT_APP_API_URI}/api/nos-publication?populate=*`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Origin: ''
    }
  }).then((response) => response.json());

// fetch(`${process.env.REACT_APP_API_URI}/api/nos-publications?populate=*`, {
//   headers: {
//     Accept: 'application/json',
//     'Content-Type': 'application/json',
//     Origin: ''
//   }
// }).then((response) => response.json());

export const getMentionsLegales = () =>
  fetch(`${process.env.REACT_APP_API_URI}/api/mentions-legale?populate=*`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Origin: ''
    }
  }).then((response) => response.json());

export const sendEmail = (typeContact, nom, prenom, email, adresse, tel, commentaire) => {
  // Créer l'objet contenant les informations du formulaire
  const formData = {
    typeContact: typeContact,
    nom: nom,
    prenom: prenom,
    email: email,
    commentaire: commentaire,
    cc: email
  };

  // Créer l'objet contenant les informations de l'e-mail à envoyer
  const emailData = {
    // cc: 'brahim@xerusmedia.com', // Destinataires en copie
    cc: 'loutfi.elmoutaouakil@afg-am.com,souhail.chalabi@afg-am.com,nisrine.adel@afg-am.com', // Destinataires en copie
    subject: 'Nouveau message de contact', // Objet de l'e-mail
    text: JSON.stringify(formData), // Corps de l'e-mail au format texte brut
    html: `<p>Type : ${typeContact}</p>
           <p>Nom : ${nom}</p>
           <p>Prénom : ${prenom}</p>
           <p>Email : ${email}</p>
           <p>Téléphone : ${tel}</p>
           <p>Adresse : ${adresse}</p>
           <p>Commentaire : ${commentaire}</p>` // Corps de l'e-mail au format HTML
  };
  //fetch(`${process.env.REACT_APP_API_URI}/api/contact-us`, {
  fetch(`${process.env.REACT_APP_API_URI}/api/contact-us`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(emailData)
  })
    .then((response) => {
      if (response.ok) {
        // Message de confirmation en cas de succès
        alert('Votre message a bien été envoyé !');
      } else {
        // Message d'erreur en cas d'échec
        alert('Une erreur est survenue, veuillez réessayer plus tard.');
      }
    })
    .catch((error) => {
      // Message d'erreur en cas d'échec
      alert('Une erreur est survenue, veuillez réessayer plus tard.');
      console.error("Erreur lors de l'envoi de l'e-mail :", error);
    });

    
};

export const getFondsData = (sheetLabel) =>
  fetch(
    `${process.env.REACT_APP_API_URI}/api/vl-performances-graph?fileName=historique_Performances_95e008649a.xlsx&sheetName=` +
      sheetLabel,
    {
      
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Origin: ''
      }
    }
  ).then((response) => response.json());