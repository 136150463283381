import React from 'react';

import { bool, object } from 'prop-types';

import './PresentationGenerale.scoped.scss';

PresentationGenerale.propTypes = {
  isInViewPresentationGenerale: bool,
  contentData: object,
  isDesktop: bool
};

const assetsFacteur = [
  'https://www.afgassetmanagement.com/admin/uploads/consultation_de4e90e42a.gif',
  'https://www.afgassetmanagement.com/admin/uploads/bookmark_c47edfe39b.gif',
  'https://www.afgassetmanagement.com/admin/uploads/museum_authority_c2f225c22e.gif',
  'https://www.afgassetmanagement.com/admin/uploads/star_rating_c4cd1f6b53.gif'
];

export function PresentationGenerale({ isInViewPresentationGenerale, contentData, isDesktop }) {
  return (
    <>
      <div className="section-presentation-generale">
        <div className="section-presentation-generale-container">
          <div className="side-presentation-generale-left">
            <div className="left-side-cotnainer">
              <div
                style={{
                  transform: isInViewPresentationGenerale
                    ? 'translate(0vw, 0vh)'
                    : 'translate(-21vw, 0vh)',
                  transitionDuration: '1s'
                }}
                className="title-presentation-generale">
                <div>{contentData.titleL1}</div>
                <div>{contentData.titleL2}</div>
                <div className="presentation-generale-divider" />
              </div>
              {isDesktop && (
                <div
                  style={{
                    transform: isInViewPresentationGenerale
                      ? 'translate(0vw, 0vh)'
                      : 'translate(0vw, 10vh)',
                    transitionDuration: '1s',
                    scale: 1
                  }}
                  className="presentation-generale-photo-desktop">
                  <div className="presentation-generale-img-container">
                    <img
                      style={{
                        scale: '100%'
                      }}
                      src={`${process.env.REACT_APP_API_URI}/uploads/presentation_generale_photo_f8337969c1.png`}
                    alt=""/>
                  </div>
                </div>
              )}
              {!isDesktop && (
                <div
                  style={{
                    transform: isInViewPresentationGenerale
                      ? 'translate(0vw, 0vh)'
                      : 'translate(0vw, 10vh)',
                    opacity: isInViewPresentationGenerale ? '100%' : '0%',
                    transitionDuration: '1s'
                  }}
                  className="presentation-generale-photo">
                  <img
                    src={`${process.env.REACT_APP_API_URI}/uploads/presentation_generale_photo_f8337969c1.png`}
                  alt="" />
                </div>
              )}
            </div>
          </div>

          <div className="side-presentation-generale-right">
            <div
              style={{
                transform: isInViewPresentationGenerale
                  ? 'translate(0vw, 0vh)'
                  : 'translate(0vw, -10vh)',
                transitionDuration: '1s'
              }}>
              <div>
                <div
                  style={{
                    transform: isInViewPresentationGenerale
                      ? 'translate(0vw, 0vh)'
                      : 'translate(0vw, -10vh)',
                    transitionDuration: '1s'
                  }}>
             <div className="paragraph-gestion-actifs" dangerouslySetInnerHTML={{ __html: 'AFG Asset Management est une société de gestion d’OPCVM, de type entrepreneurial, portée par une équipe de professionnels de la gestion d’actifs et adossée au groupe <a href="https://www.afginvestmentbank.com/" target="_blank">AFG Investment Bank</a> qui en est l’actionnaire de référence.' }}></div>


                  {isDesktop && <br />}
                  <div className="paragraph-gestion-actifs">{contentData.paragraphR2}</div>
                </div>
                <div className="rounded" />
                <div>
                  <div
                    style={{
                      opacity: isInViewPresentationGenerale ? '1' : '0',
                      transitionDuration: '2s'
                    }}
                    className="facteurs-cles">
                    <div>{contentData.titleR21}&nbsp;</div>
                    <div>{contentData.titleR22}</div>
                  </div>
                  <div className="band-facteurs-container">
                    <div className="band-facteurs">
                      {contentData.facteursCles?.map((fl, index) => (
                        <div
                          style={{
                            transform: isInViewPresentationGenerale
                              ? 'translate(0vw, 0vh)'
                              : 'translate(10vw, 0)',
                            transitionDuration: 0.5 * (index + 1) + 's'
                          }}
                          key={index}
                          className="facteur-container">
                          <img src={assetsFacteur.at(index)} alt="" />
                          <div className="facteur-text-container">
                            <div className="facteur">{fl}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
