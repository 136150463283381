import React, { useEffect, useMemo, useState, useRef } from 'react';

import { bool, object, string } from 'prop-types';
import './FcpManagementSquars1.scoped.scss';

import { getExcelGraphData } from '../../../api/api';
import { fiche } from '../../performances/section-4-gamme/StackedCards';


export function useIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () => new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting)),
    []
  );

  useEffect(() => {
    if (ref.current instanceof Element) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}

FcpManagementSquars1.propTypes = {
  expandLevels: bool,
  grey: bool,
  transition2: bool,
  refSquars2: object,
  transitionSquars: bool,
  isDesktop: bool,
  contentData: object,
  idProduit: string
};

const TRANSITION_DURATION = 2;

export function FcpManagementSquars1({
  transition2,
  refSquars2,
  expandLevels,
  grey,
  transitionSquars,
  isDesktop,
  contentData,
  idProduit
}) {

  const [hasDataBeenFetched, setHasDataBeenFetched] = useState(false);
  const [excelData, setExcelData] = useState(null);

  const sheetLabel = fiche.find((prod) => prod?.id === Number(idProduit)).sheetName;

  useEffect(() => {
    if (!hasDataBeenFetched) {
      setHasDataBeenFetched(true);
      getExcelGraphData(sheetLabel).then((data) => {
        setExcelData(data);
        setHasDataBeenFetched(true);
      });
    }
  }, [idProduit, excelData]);

  let lastDataRow   = "";
  let lastVL        = "";
  let lastAN        = "";
  let lastDate      = null;

  if(excelData) {
    lastDataRow     = excelData[excelData.length - 1];
    lastVL          = lastDataRow[lastDataRow.length - 9]; 
    lastAN          = lastDataRow[lastDataRow.length - 10]; 
    lastDate        = lastDataRow[lastDataRow.length - 15];
    lastDate        = new Date((lastDate - 25569) * 86400 * 1000);
    lastDate        = lastDate.toLocaleDateString('fr-FR');
  }

  const getMarginLeft = (i) => (isDesktop ? 3.8 * i + 'vw' : 57 * i + 'px');
  const NUMBER_LEVELS = 5;
  const risque = contentData?.carree12?.risque;
  const GREY_TO_USE = isDesktop ? '#F7F7FA' : '#E4E5EA';
  const shiftForAppBar = isDesktop ? '14.722vh' : 0;
  
  return (
    <>
      <div className="section-2-fcp-management">
        <div
          style={{
            transform: !transition2 || !isDesktop ? 'translate(0vw, -12vh)' : 'translate(0, 0)',
            transitionDuration: `1s`
          }}
          className="fcp-management-sides-container">
          <div
            style={{
              transform:
                transitionSquars || !isDesktop
                  ? 'translate(0vw, 14.722vh)'
                  : 'translate(-5.208333333333334vw, 30vh)',
              transitionDuration: `${TRANSITION_DURATION}s`
            }}
            className="fcp-management-1-side-1">
            <div className="fcp-management-side-1-titre">{contentData?.carree11?.titre} </div>
            <div className="fcp-management-side-1-sous-titre">
               {/* {contentData?.carree11?.sousTitre}<br/>   */}
               Date de dernière valeur<br />
              {lastDate} 
              {/* {contentData?.carree11?.date} */}
            </div>
            <div className="fcp-management-side-1-grp-periodicite">
              <div className="fcp-management-side-1-label-periodicite">
                {contentData?.carree11?.periodicteTitre}
              </div>
              <div className="fcp-management-side-1-value-periodicite">
                {contentData?.carree11?.periodiciteValeur}
              </div>
            </div>
          </div>
          <div
            style={{
              transform:
                transitionSquars || !isDesktop
                  ? 'translate(0vw, 14.722vh)'
                  : 'translate(5.208333333333334vw, 30vh)',
              transitionDuration: `${TRANSITION_DURATION}s`
            }}
            className="fcp-management-1-side-2">
            <div className="fcp-management-1-side-2-titre">{contentData?.carree12?.titre}</div>
            <div className="profil-risque-container">
              <div className="profil-risque-titres-container">
                <div className="profil-titre-elmnt">
                  {contentData?.carree12?.libelleRisqueFaible}
                </div>
                <div className="profil-titre-elmnt">{contentData?.carree12?.libelleRisqueFort}</div>
              </div>
              <div className="profil-risque-dividers-colored-container">
                {risque &&
                  [...Array(risque).keys()].map((index) => (
                    <div
                      key={index}
                      style={{
                        borderBottom: '1vh solid #C1A367',
                        position: 'absolute',
                        marginLeft: expandLevels ? getMarginLeft(index) : 0,
                        transitionDuration: `${TRANSITION_DURATION}s`
                      }}
                      className="profil-risque-divider-colored"
                    />
                  ))}
                {risque &&
                  [...Array(NUMBER_LEVELS - risque).keys()].map((index) => (
                    <div
                      key={index}
                      style={{
                        borderBottom: `1vh solid ${grey ? GREY_TO_USE : '#C1A367'}`,
                        position: 'absolute',
                        marginLeft: expandLevels ? getMarginLeft(risque + index) : 0,
                        transitionDuration: `${TRANSITION_DURATION}s`
                      }}
                      className="profil-risque-divider-colored"
                    />
                  ))}
              </div>

              <div className="profil-risque-divider" />
              <div className="profil-titre-legend">{contentData?.carree12?.legendeRisque}</div>
            </div>
          </div>
        </div>
        <div className="squars-1-sides-container">
          <div
            style={{
              transform:
                transition2 || !isDesktop
                  ? `translate(0vw, ${shiftForAppBar})`
                  : 'translate(-5.208333333333334vw, 30vh)',
              transitionDuration: `${TRANSITION_DURATION}s`
            }}
            className="valeur-management-side-1">
            <div ref={refSquars2} className="chiffre-valeur-side-1-titre">
              {/* {contentData?.evolutionPerf?.titre} */}
             {lastVL.toLocaleString(undefined,{ minimumFractionDigits: 2 })} <span className="devise-side-1-sous-titre">{contentData?.carree21?.sousChiffre}</span>
             {/* {parseFloat(lastVL).toFixed(2)}  */}
            </div>
            {/* <div className="devise-side-1-sous-titre">{contentData?.carree21?.sousChiffre}</div> */}
            <div className="valeur-liquid-1-sous-titre">{contentData?.carree21?.textValeur}</div>
          </div>
          <div
            style={{
              transform:
                transition2 || !isDesktop
                  ? `translate(0vw, ${shiftForAppBar})`
                  : 'translate(5.208333333333334vw, 30vh)',
              transitionDuration: `${TRANSITION_DURATION}s`
            }}
            className="actif-side-2">
            <div ref={refSquars2} className="chiffre-valeur-side-1-titre">
             {/* {contentData?.carree22?.chiffre}<br/> */}
              {lastAN.toLocaleString(undefined,{ minimumFractionDigits: 2 })} <span className="devise-side-1-sous-titre">{contentData?.carree22?.sousChiffre}</span>
              {/* {parseFloat(lastAN).toFixed(2)}  */}
            </div>
            {/* <div className="devise-side-1-sous-titre">{contentData?.carree22?.sousChiffre}</div> */}
            <div className="valeur-liquid-1-sous-titre">{contentData?.carree22?.textValeur}</div>
          </div>
        </div>
      </div>
    </>
  );
}
