import React, { useState } from 'react';

import LockPersonIcon from '@mui/icons-material/LockPerson';

import './NosPublications.scoped.scss';
import { bool, string } from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { downloadDoc } from '../../api/api';

PdfItem.propTypes = {
  isDesktop: bool,
  title: string,
  subTitle: string,
  isAuthenticated: bool,
  url: string
};
export function PdfItem({ isDesktop, title, subTitle, isAuthenticated, url }) {
  const navigate = useNavigate();
  const location = useLocation();
  const handleClick = () => {
    sessionStorage.setItem('previousLocation', JSON.stringify(location));
    navigate('/login');
  };


  // const onClickDownloadPdf = () => {
  //   const directUrl = "http://localhost:1337/uploads/Monthly_FCP_AFG_Optimal_Fund_Mai_2023_bd9cbc857e.pdf"; // Remplacez par votre URL directe
  //   const link = document.createElement('a');
  //   link.href = directUrl;
  //   link.setAttribute('download', subTitle);
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  const onClickDownloadPdf = () => {
    downloadDoc(url)
      .then((response) => response.data)
      .then((blob) => {
        // Create blob link to download
        // const url = window.URL.createObjectURL(new Blob([blob]));
        const url = window.URL.createObjectURL(new Blob([blob], { 
          type: 'application/pdf',
          responseType: 'blob',
         }));

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', subTitle);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      });
  };

  const [isHovered, setIsHovered] = useState(false);
  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        if (isAuthenticated) {
          onClickDownloadPdf();
        } else {
          handleClick();
        }
      }}
      style={{ cursor: isAuthenticated ? 'pointer' : 'unset' }}
      className="doc-pdf">
      <div className="doc-pdf-container">
        <LockPersonIcon
          style={{
            position: 'relative',
            top: isDesktop ? '62px' : '100px',
            opacity: !isAuthenticated && isHovered ? 1 : 0,
            color: '#c1a367',
            zIndex: 10,
            transitionDuration: '0.5s'
          }}
        />
        <div className="doc-pdf-img-container">
          <img
            alt="alt"
            className="doc-pdf-img"
            style={{ opacity: !isAuthenticated && isHovered ? 0.25 : 1 }}
            src={`${process.env.REACT_APP_API_URI}/uploads/pngegg_4b8a4fb240.png`}
          />
        </div>
        <div className="np-pdf-title">{title}</div>

        <Tooltip title={subTitle ? subTitle : ''}>
          <div className="np-pdf-sub-title">{subTitle}</div>
        </Tooltip>
        <div
          style={{ opacity: isHovered ? 1 : 0, transitionDuration: '0.5s' }}
          className="np-pdf-tooltip">
          {isAuthenticated
            ? 'Cliquez pour télécharger'
            : 'Connectez vous pour télécharger le document'}
        </div>
      </div>
    </div>
  );
}
