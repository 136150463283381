import React from 'react';

import { bool, func, string } from 'prop-types';
import { TextField } from '@mui/material';

import './TextFieldUI.scoped.scss';

TextFieldUI.propTypes = {
  value: string,
  setValue: func,
  label: string,
  multiline: bool,
  onChange: func,
  type: string,
  disabled: bool
};

export function TextFieldUI({ value, label, multiline, onChange, type, disabled }) {
  return (
    <>
      <TextField
        type={type}
        style={{
          display: 'flex'
        }}
        InputLabelProps={{
          style: {
            fontFamily: 'Aileron-Regular',
            fontWeight: 200,
            fontSize: '13px'
          }
        }}
        inputProps={{
          style: {
            minHeight: multiline ? '70px' : 'unset'
          }
        }}
        /* styles the input component */
        id={`outlined-basic-${label}`}
        label={label}
        fullWidth
        variant="outlined"
        onChange={onChange}
        value={value}
        multiline={multiline}
        maxRows={10}
        disabled={disabled}
      />
    </>
  );
}
