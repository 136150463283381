import React, { useEffect, useRef, useState } from 'react';

import { useIsInViewport } from './afg-monetaire/section-2-FcpManagement/FcpManagement';

import './TestScroll.scoped.scss';

export function TestScroll() {
  const ref1 = useRef();
  const ref2 = useRef();

  const ref3 = useRef();
  const [listRef, setListRef] = useState([ref1]);
  const handleScroll = (ref) => {
    window.scrollTo({
      behavior: 'smooth',
      top: ref.current.offsetTop
    });
  };
  // const isInViewSection1 = useIsInViewport(ref1)
  const isInViewSection2 = useIsInViewport(ref2);
  const isInViewSection3 = useIsInViewport(ref3);

  useEffect(() => {
    // if (listRef.length === 0) {
    //   setListRef(listRef.concat(ref1))
    // }
  }, [isInViewSection2, isInViewSection3, listRef]);

  useEffect(() => {
    if (listRef.length > 1) {
      if (listRef[listRef.length - 1] !== ref2 && isInViewSection2) {
        handleScroll(ref2);
        setListRef(listRef.concat(ref2));
      }

      if (isInViewSection3) {
        if (listRef[listRef.length - 1] === ref2) {
          handleScroll(ref2);
          setListRef(listRef.concat(ref2));
        }
      }
    }
  }, [isInViewSection2, isInViewSection3, listRef]);

  return (
    <>
      <div className="container">
        <section id="section-1">
          <div ref={ref1} className="section1" />
        </section>
        <section id="section-2">
          <div ref={ref2} className="section2" />
        </section>
        <section id="section-3">
          <div ref={ref3} className="section3" />
        </section>
        <section id="section-4">
          <div className="section4" />
        </section>
        <section id="section-5">
          <div className="section5" />
        </section>
      </div>
    </>
  );
}
