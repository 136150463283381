/* eslint-disable no-underscore-dangle */
import React from 'react';

import { object } from 'prop-types';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  LinearScale,
  LineElement,
  PointElement,
  registerables,
  Title,
  Tooltip
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  ...registerables,
  zoomPlugin,
  CategoryScale,
  Filler,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip
);

LineChart.propTypes = {
  data: object,
  chartRef: object,
  checkedId: object
};

export function LineChart({ data, chartRef, checkedId }) {
  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          title: (xDatapoint) => {
            const timestamp = data?.labels[xDatapoint[0]?.dataIndex];
            const date = new Date((timestamp - 25569) * 86400 * 1000);
            return date.toLocaleDateString('fr-FR', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            });
          }
          // Other options for tooltips such as label, etc.
        }
      },
      legend: {
        display: false
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart'
      },
      zoom: {
        zoom: {
          drag: {
            enabled: true
          },
          mode: 'x'
        }
      }
    },
    events: ['mousemove', 'mouseout', 'click', 'mousedown', 'touchmove'],
    interaction: {
      intersect: false
    },
    scales: {
      x: {
        ticks: {
          callback: function (dataIndex) {
            const timestamp = data?.labels[dataIndex];
            const date = new Date((timestamp - 25569) * 86400 * 1000);
            const formattedDate = `${
              checkedId?.id > 1 || checkedId?.id > 2 ? date.getDate() : ''
            } ${date.toLocaleDateString('fr-FR', {
              month: 'short'
            })} ${date.getFullYear()}`;
            return formattedDate;
          },
          color: 'white'
        }
      },
      y: {
        ticks: {
          callback: function (dataIndex) {
            return dataIndex.toFixed(2);
          },
          color: 'white'
        }
      }
    }
  };
  // attached the map function
  return <Line options={options} data={data} ref={chartRef} />;
}
