import React, { useRef, useState } from 'react';

import { bool, string } from 'prop-types';

import './NosClientsPage.scoped.scss';

Card.propTypes = {
  title: string,
  paragraph: string,
  iconeUrl: string,
  isInViewNosClients: bool
};

export function Card({ title, paragraph, iconeUrl, isInViewNosClients }) {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isShown, setIsShown] = useState(false);
  const divRef = useRef(null);
  function handleMouseMove(event) {
    const rect = event.target.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    setPosition({ x, y });
  }

  const rotateX = isShown ? ((-120 + position.x) / 120) * 8 : 0;
  const rotateY = isShown ? ((200 - position.y) / 200) * 8 : 0;

  return (
    <>
      <div
        style={{
          transition: 'perspective 250ms, rotateY 250ms, rotateX 250ms, transform 1s',
          transform:
            'perspective(1000px) rotateY(' +
            rotateX +
            'deg)' +
            'rotateX(' +
            rotateY +
            'deg) ' +
            (isInViewNosClients ? 'translate(0vw, 0vh)' : 'translate(0, 15vh)'),
          transitionDuration: '0.05s, 0.05s, 0.05s, 1s'
        }}
        ref={divRef}
        onMouseMove={handleMouseMove}
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
        className="card-client">
        <div className="card-content-container">
          <div className="logo">
            <img src={iconeUrl} alt=""/>
          </div>
          <div className="card-title">
            <div>{title}</div>
          </div>
          <div className="card-text">{paragraph}</div>
        </div>
      </div>
    </>
  );
}
