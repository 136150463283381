import React from 'react';

import './Presentation.scoped.scss';

export function Presentation() {
  document.body.classList.remove('hidden-scrollbar-y');
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${process.env.REACT_APP_API_URI}/uploads/Gif_section_1_ac3b67acd3.gif)`,
          backgroundSize: 'cover',
          backgroundPositionX: 'center'
        }}
        className="section-nos-fonds">
        <div className="section-nos-fonds-text">Nos Fonds</div>
      </div>
    </>
  );
}
