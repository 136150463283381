import React from 'react';

import { Button, TextField } from '@mui/material';

import { ResponsiveAppBar } from '../common/ResponsiveAppBar';

import './Inscription.scoped.scss';

export function Inscription() {
  const [focusedNom, setFocusedNom] = React.useState(false);
  const [focusedPrenom, setFocusedPrenom] = React.useState(false);
  const [focusedEmail, setFocusedEmail] = React.useState(false);
  const onFocusNom = () => setFocusedNom(true);
  const onBlurNom = () => setFocusedNom(false);
  const onFocusPrenom = () => setFocusedPrenom(true);
  const onBlurPrenom = () => setFocusedPrenom(false);
  const onFocusEmail = () => setFocusedEmail(true);
  const onBlurEmail = () => setFocusedEmail(false);
  const textFieldHeight = '4.722222222222222vh';
  const labelOffset = 0;
  return (
    <>
      <ResponsiveAppBar />
      <div className="section-inscription">
        <div className="section-inscription-container">
          <div className="section-inscription-container-bis">
            <div className="inscription-title">Bienvenue !</div>
            <div className="divider-inscription" />
            <div className="field-nous-contacter">
              <TextField /* styles the wrapper */
                style={{ height: textFieldHeight }}
                /* styles the label component */
                InputLabelProps={{
                  style: {
                    ...(!focusedNom && { top: `${labelOffset}vh` }),
                    fontSize: '1.0416666666666667vw'
                  }
                }}
                /* styles the input component */
                inputProps={{
                  style: {
                    height: textFieldHeight,
                    padding: '0 0.7291666666666667vw'
                  }
                }}
                id="outlined-basic"
                label="NOM"
                fullWidth
                onFocus={onFocusNom}
                onBlur={onBlurNom}
                on
                variant="outlined"
              />
            </div>
            <div className="field-nous-contacter">
              <TextField
                style={{ height: textFieldHeight }}
                /* styles the label component */
                InputLabelProps={{
                  style: {
                    ...(!focusedPrenom && { top: `${labelOffset}vh` }),
                    fontSize: '1.0416666666666667vw'
                  }
                }}
                /* styles the input component */
                inputProps={{
                  style: {
                    height: textFieldHeight,
                    padding: '0 0.7291666666666667vw'
                  }
                }}
                id="outlined-basic"
                label="PRENOM"
                fullWidth
                onFocus={onFocusPrenom}
                onBlur={onBlurPrenom}
                on
                variant="outlined"
              />
            </div>
            <div className="field-nous-contacter">
              <TextField
                style={{ height: textFieldHeight }}
                /* styles the label component */
                InputLabelProps={{
                  style: {
                    ...(!focusedEmail && { top: `${labelOffset}vh` }),
                    fontSize: '1.0416666666666667vw'
                  }
                }}
                /* styles the input component */
                inputProps={{
                  style: {
                    height: textFieldHeight,
                    padding: '0 0.7291666666666667vw'
                  }
                }}
                id="outlined-basic"
                label="E-MAIL"
                fullWidth
                onFocus={onFocusEmail}
                onBlur={onBlurEmail}
                on
                variant="outlined"
              />
            </div>
            <div className="buttons-container-inscription">
              <div className="buttons-container-inscription-bis">
                <div className="buttons-container-inscription-bis-2">
                  <div className="field-inscription-envoyer">
                    <Button
                      fullWidth
                      sx={{
                        background: '#E4E5EA',
                        fontSize: '1.1458333333333335vw',
                        color: '#282E49'
                      }}
                      variant="contained"
                      color="success"
                      disableElevation>
                      S&apos;inscrire
                    </Button>
                  </div>

                  <div className="field-inscription-envoyer">
                    <Button
                      fullWidth
                      disableElevation
                      sx={{
                        background: '#282E49',
                        fontSize: '1.1458333333333335vw'
                      }}
                      variant="contained"
                      color="success">
                      se connecter
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
