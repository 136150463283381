import React, { useEffect, useRef, useState } from 'react';

import { LineChart } from './LineChart';

import './EvolutionPerformance.scoped.scss';
import { bool, object, string } from 'prop-types';
import { getExcelGraphData } from '../../../api/api';
import { fiche } from '../../performances/section-4-gamme/StackedCards';
import { Box } from '@mui/system';
import { CircularProgress } from '@mui/material';

EvolutionPerformance.propTypes = {
  isDesktop: bool,
  contentData: object,
  idProduit: string
};


export function EvolutionPerformance({ isDesktop, contentData, idProduit }) {
  const [hasDataBeenFetched, setHasDataBeenFetched] = useState(false);
  const [excelData, setExcelData] = useState(null);
  const sheetLabel = fiche.find((prod) => prod?.id === Number(idProduit)).sheetName;
  
  useEffect(() => {
    if (!hasDataBeenFetched) {
      setHasDataBeenFetched(true);
      getExcelGraphData(sheetLabel).then((data) => {
        setExcelData(data);
        setHasDataBeenFetched(true);
      });
    }
  }, [idProduit, excelData]);
  
  
  let lastDate = new Date();
  // let lastDate      = null;

  if (excelData) {
    lastDate = new Date((excelData[excelData?.length - 1][0] - 25569) * 86400 * 1000);
  }


  const [checkedId, setCheckedId] = useState({
    id: 7,
    label: 'YTD',
    minDate: new Date(lastDate.getFullYear(), 0, 1)
  });

  const excelDataFiltered = excelData?.filter(
    (row) => new Date((row[0] - 25569) * 86400 * 1000) > checkedId?.minDate
  );
 
  const buttons = [
    {
      id: 7,
      label: 'YTD',
      minDate: new Date(lastDate.getFullYear(), 0, 1)
    },
    {
      id: 9,
      label: '1 semaine',
      minDate: new Date(lastDate.getFullYear(), lastDate.getMonth(), lastDate.getDate() - 7)
    },
    {
      id: 10,
      label: '1 mois',
      minDate: new Date(
        lastDate.getFullYear(),
        lastDate.getMonth() - 1,
        Math.min(
          lastDate.getDate(),
          new Date(lastDate.getFullYear(), lastDate.getMonth(), 0).getDate()
        )
      )
    },
    {
      id: 11,
      label: '3 mois',
      minDate: new Date(lastDate.getFullYear(), lastDate.getMonth() - 3, lastDate.getDate())
    },
    {
      id: 12,
      label: '6 mois',
      minDate: new Date(lastDate.getFullYear(), lastDate.getMonth() - 6, lastDate.getDate())
    },
    {
      id: 13,
      label: '1 an',
      minDate: new Date(lastDate.getFullYear() - 1, lastDate.getMonth(), lastDate.getDate())
    },
    {
      id: 14,
      label: '2 ans',
      minDate: new Date(lastDate.getFullYear() - 2, lastDate.getMonth(), lastDate.getDate())
    }
  ];
  const labels = excelDataFiltered?.map((row) => row[0]);
  const datas = buttons
    .map((button) => button.id)
    .map((id) => {
      return {
        id,
        data: excelDataFiltered?.map((row) => row[6])
      };
    });

  const data = {
    labels: labels,
    datasets: datas.map((dataX) => {
      return {
        label: 'VL',
        type: 'line',
        fill: 'start',
        data: dataX.data,
        borderColor: 'rgb(124, 181, 236, 1)',
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 400);
          gradient.addColorStop(0, 'rgb(124, 181, 236, 1)');
          gradient.addColorStop(1, 'rgb(124, 181, 236, 0)');
          return gradient;
        },
        hidden: checkedId?.id !== dataX?.id
      };
    })
  };
  const onClick = (id) => setCheckedId(buttons.find((button) => button?.id === id));
  const chartRef = useRef();
  const onClickResetZoom = () => {
    if (chartRef && chartRef.current) {
      chartRef.current.resetZoom();
    }
  };
  return (
    <>
      <div className="section-7-evolution-performances">
        <div className="section-evolution-performances-container-background">
          <div>
            <div className="section-evolution-performances-container">
              <div className="section-titre-evolution-performances">
                {contentData?.evolutionPerf?.titre}
              </div>
              <div
                style={{
                  display: isDesktop ? 'flex' : 'unset',
                  justifyContent: 'space-between',
                  marginLeft: '30px',
                  height: '40px',
                  lineHeight: '40px',
                  borderBottom: '1px solid rgb(124, 181, 236, 1)'
                }}>
                {buttons.map((button) => (
                  <div
                    key={button?.id}
                    style={{
                      cursor: 'pointer',
                      transitionDuration: '0.5s',
                      ...(checkedId?.id === button?.id
                        ? {
                            backgroundColor: 'rgb(124, 181, 236, 1)',
                            height: '40px',
                            lineHeight: '40px',
                            padding: '0 10px 0 10px',
                            borderTopLeftRadius: '20px',
                            borderTopRightRadius: '20px',
                            color: 'black',
                            minWidth: '50px'
                          }
                        : {})
                    }}
                    onClick={() => onClick(button?.id)}
                    className="radio-annee">
                    {button.label}
                  </div>
                ))}
                {isDesktop && (
                  <div
                    style={{
                      background: '#c1a367',
                      color: '#282E49',
                      width: '150px',
                      cursor: 'pointer',
                      textTransform: 'uppercase'
                    }}
                    onClick={onClickResetZoom}>
                    {contentData?.evolutionPerf?.buttonReset}
                  </div>
                )}
              </div>
            </div>
            <div className="graph-evolution-perofrmances-container">
              {!excelDataFiltered && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: 10,
                    transform: 'scale(3)'
                  }}>
                  <CircularProgress />
                </Box>
              )}
              <div
                style={{
                  marginBottom: '15vh',
                  height: isDesktop ? '40vh' : '100px',
                  width: isDesktop ? '60vw' : 'calc(100vw - 60px)',
                  maxHeight: !isDesktop && '100px',
                  marginLeft: isDesktop ? 'unset' : '30px',
                  marginRight: isDesktop ? 'unset' : '30px'
                }}>
                <LineChart data={data} chartRef={chartRef} checkedId={checkedId} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
