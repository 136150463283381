import { object } from 'prop-types';
import React from 'react';

import './CaracteristiqueGestion.scoped.scss';

CaracteristiqueGestion.propTypes = {
  contentData: object
};
export function CaracteristiqueGestion({ contentData }) {
  return (
    <>
      <div className="section-carac-gestion">
        <div className="section-titre-cara">
          {contentData?.caracteristiquesFonds?.titrePrincipal}
        </div>
        <div className="divider-carc" />
        <div className="carac-column-container">
          <table>
            <tbody>
              <tr>
                <td className="carac-column-black">
                  <div className="carac-row-black">
                    {contentData?.caracteristiquesFonds?.libelleDateLancement}
                  </div>
                </td>
                <td className="carac-column-grey-1">
                  <div className="carac-row-grey">
                    {contentData?.caracteristiquesFonds?.valeurDateLancement}
                  </div>
                </td>
              </tr>
              <tr>
                <td className="carac-column-black">
                  <div className="carac-row-black">
                    {contentData?.caracteristiquesFonds?.libelleCategorie}
                  </div>
                </td>
                <td className="carac-column-grey-1">
                  <div className="carac-row-grey">
                    {contentData?.caracteristiquesFonds?.valeurCategorie}
                  </div>
                </td>
              </tr>
              <tr>
                <td className="carac-column-black">
                  <div className="carac-row-black">
                    {contentData?.caracteristiquesFonds?.libelleFormeJuridique}
                  </div>
                </td>
                <td className="carac-column-grey-1">
                  <div className="carac-row-grey">
                    {contentData?.caracteristiquesFonds?.valeurFormeJuridique}
                  </div>
                </td>
              </tr>
              <tr>
                <td className="carac-column-black">
                  <div className="carac-row-black">
                    {contentData?.caracteristiquesFonds?.libelleCodeMaro}
                  </div>
                </td>
                <td className="carac-column-grey-1">
                  <div className="carac-row-grey">
                    {contentData?.caracteristiquesFonds?.valeurCodeMaro}
                  </div>
                </td>
              </tr>
              <tr>
                <td className="carac-column-black">
                  <div className="carac-row-black">
                    {contentData?.caracteristiquesFonds?.libelleReseauCom}
                  </div>
                </td>
                <td className="carac-column-grey-1">
                  <div className="carac-row-grey">
                    {contentData?.caracteristiquesFonds?.valeurReseauCom}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td className="carac-column-black">
                  <div className="carac-row-black">
                    {contentData?.caracteristiquesFonds?.libelleTransmissionOrd}
                  </div>
                </td>
                <td className="carac-column-grey-1">
                  <div className="carac-row-grey">
                    {contentData?.caracteristiquesFonds?.valeurTransmissionOrd}
                  </div>
                </td>
              </tr>
              <tr>
                <td className="carac-column-black">
                  <div className="carac-row-black">
                    {contentData?.caracteristiquesFonds?.libellePeriodicite}
                  </div>
                </td>
                <td className="carac-column-grey-1">
                  <div className="carac-row-grey">
                    {contentData?.caracteristiquesFonds?.valeurPeriodicite}
                  </div>
                </td>
              </tr>
              <tr>
                <td className="carac-column-black">
                  <div className="carac-row-black">
                    {contentData?.caracteristiquesFonds?.libelleIndiceRef}
                  </div>
                </td>
                <td className="carac-column-grey-1">
                  <div className="carac-row-grey">
                    {contentData?.caracteristiquesFonds?.valeurIndiceRef}
                  </div>
                </td>
              </tr>
              <tr>
                <td className="carac-column-black">
                  <div className="carac-row-black">
                    {contentData?.caracteristiquesFonds?.libelleSouscripteurConc}
                  </div>
                </td>
                <td className="carac-column-grey-1">
                  <div className="carac-row-grey">
                    {contentData?.caracteristiquesFonds?.valeurSouscripteurConc}
                  </div>
                </td>
              </tr>
              <tr>
                <td className="carac-column-black">
                  <div className="carac-row-black">
                    {contentData?.caracteristiquesFonds?.libelleBanqueDepo}
                  </div>
                </td>
                <td className="carac-column-grey-1">
                  <div className="carac-row-grey">
                    {contentData?.caracteristiquesFonds?.valeurBanqueDepo}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
