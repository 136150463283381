import React from 'react';

import { Button, Checkbox, FormControlLabel, FormGroup, Radio, RadioGroup } from '@mui/material';

import { TextFieldUI } from '../common/ui-components/TextFieldUI';

import './NousContacter.scoped.scss';
import { bool } from 'prop-types';
import { sendEmail } from '../../api/api';

NousContacter.propTypes = {
  isDesktop: bool
};

export function NousContacter({ isDesktop }) {
  document.body.classList.remove('hidden-scrollbar-y');
  const [valueNom, setValueNom] = React.useState('');
  const [valuePrenom, setValuePrenom] = React.useState('');
  const [valueEmail, setValueEmail] = React.useState('');
  const [valueTel, setValueTel] = React.useState('');
  const [valueAdresse, setValueAdresse] = React.useState('');
  const [valueCommentaire, setValueCommentaire] = React.useState('');
  const [confirmedCG, setConfirmedCG] = React.useState(false);
  const [type, setType] = React.useState('Demande d\'information');
  const onClickSendEmail = () => {
    sendEmail(type, valueNom, valuePrenom, valueEmail, valueAdresse, valueTel, valueCommentaire);
  };
  const onClickCheckBoxCG = () => {
    setConfirmedCG(!confirmedCG);
  };
  const handleChange = (event) => {
    setType(event.target.value);
  };
  const disableSend = valueNom === '' || valuePrenom === '' || valueEmail === '' || !confirmedCG;
  return (
    <>
      <div className="section-nous-contacter">
        <div
          style={{ marginTop: isDesktop ? '8vh' : '30px' }}
          className="section-nous-contacter-container">
          <div className="section-nous-contacter-container-bis">
            <div className="nous-contacter-title">Nous contacter</div>
            <div
              style={{ marginTop: isDesktop ? '2.962962962962963vh' : 'unset' }}
              className="divider-nous-contacter"
            />
            <div className="field-nous-contacter">
              <TextFieldUI
                value={valueNom}
                onChange={(e) => setValueNom(e.target.value)}
                label="NOM*"
              />
            </div>
            <div className="field-nous-contacter">
              <TextFieldUI
                value={valuePrenom}
                onChange={(e) => setValuePrenom(e.target.value)}
                label="PRENOM*"
              />
            </div>
            <div className="field-nous-contacter">
              <TextFieldUI
                value={valueEmail}
                onChange={(e) => setValueEmail(e.target.value)}
                label="E-MAIL*"
              />
            </div>
            <div className="field-nous-contacter">
              <TextFieldUI
                value={valueTel}
                onChange={(e) => setValueTel(e.target.value)}
                label="Téléphone"
              />
            </div>
            <div className="field-nous-contacter">
              <TextFieldUI
                value={valueAdresse}
                onChange={(e) => setValueAdresse(e.target.value)}
                label="ADRESSE"
                multiline
              />
            </div>
            <div className="field-nous-contacter">
              <TextFieldUI
                value={valueCommentaire}
                onChange={(e) => setValueCommentaire(e.target.value)}
                label="MESSAGE"
                multiline
              />
            </div>
            <div className="nous-contacter-menu">
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="Demande d'information"
                onChange={handleChange}
                name="radio-buttons-group">
                <div>
                  <FormControlLabel
                    value="Demande d'information"
                    control={<Radio />}
                    label="Demande d'information"
                  />
                </div>
                <div>
                  <FormControlLabel value="Réclamation" control={<Radio />} label="Réclamation" />
                </div>
                <div>
                  <FormControlLabel value="Autre" control={<Radio />} label="Autre" />
                </div>
              </RadioGroup>
            </div>

            <div
              className="field-nous-contacter"
              style={{ fontFamily: 'Aileron-Regular', marginTop: '30px' }}>
              <div style={{ textAlign: 'left' }}>
                Conformément à la loi 09-08, vous disposez d&apos;un droit d&apos;accès, de
                rectification et d&apos;opposition au traitement de vos données personnelles. Ce
                traitement a été déclaré auprès de la CNDP sous le numéro D-740/2023.
              </div>
              <div style={{ display: 'flex', marginTop: '20px' }}>
                <FormGroup>
                  <FormControlLabel control={<Checkbox onChange={onClickCheckBoxCG} />} />
                </FormGroup>
                <div style={{ textAlign: 'left' }}>
                  j&apos;ai lu et j’accepte les informations légales, notamment{' '}
                  <a
                    onClick={() => window.open(`${window.location.origin}/mentions-legales`)}
                    style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}>
                    les conditions générales
                  </a>{' '}
                  d&apos;utilisation et la mention relative à la protection des données personnelles
                </div>
              </div>
            </div>
            <div className="field-nous-contacter-envoyer">
              <Button
                disableElevation
                onClick={onClickSendEmail}
                sx={{
                  background: '#282E49',
                  ':hover': {
                    bgcolor: '#c1a367'
                  }
                }}
                disabled={disableSend}
                variant="contained">
                Envoyer
              </Button>
              <div
                className="field-nous-contacter"
                style={{ fontFamily: 'Aileron-Regular', marginTop: '30px' }}>
                <div style={{ textAlign: 'left' }}>* : Champ obligatoire</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
