import React, { useEffect, useState } from "react";

import "./VlPerformances.scoped.scss";
import { bool } from "prop-types";
import { MenusPerf } from "./MenusPerf";
import { getExcelTableauData } from "../../api/api";
import { fiche } from "../performances/section-4-gamme/StackedCards";
import { Box, CircularProgress } from "@mui/material";

VlPerformances.propTypes = {
  isDesktop: bool,
};

function formatNumber(num) {
  const number = new Number(num);
  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: true,
    currency: "EUR",
  };
  return number.toLocaleString("fr-FR", options);
}

// function convertToPercentage(num) {
//   if (typeof num !== "number") {
//     return num;
//   }

//   return num.toFixed(2) + "%";
// }
export function VlPerformances({ isDesktop }) {
  document.body.classList.remove("hidden-scrollbar-y");
  const [hasDataBeenFetched, setHasDataBeenFetched] = useState(false);
  const [data, setData] = useState(null);

  const sheetLabels = fiche.map((prod) => prod.sheetName);

  useEffect(() => {
    if (!hasDataBeenFetched) {
      setHasDataBeenFetched(true);
      getExcelTableauData(sheetLabels).then((data) => {
        setData(data);
        setHasDataBeenFetched(true);
      });
    }
  }, []);

  const dataFormated = data?.map((x) => {
    

    return {

      codeIsin: x[1],
      opcvm: x[2],
      classification: x[3],
      horizonPlacement: x[4],
      an: x[5],
      vl: x[6],
      ytd: x[7],
      unJour: x[8],
      uneSemaine: x[9],
      unMois: x[10],
      troisMois: x[11],
      sixMois: x[12],
      unAn: x[13],
      deuxAns: x[14],
    };
  });
  
  const [performanceKey, setPerformanceKey] = React.useState({
    key: "ytd",
    label: "YTD",
  });

  // let frenchDate = "";
  // if(data) {
    
  //   let excelSerialDate = data[0];
  //   const date = new Date(1900, 0, excelSerialDate - 15); // Subtract 1 from the serial date

  //   const options = { year: "numeric", month: "long", day: "numeric" };
  //   frenchDate = date.toLocaleDateString("fr-FR", options);
  // }


  let lastDataRow   = "";
  let lastDate      = null;

  if(data) {
    lastDataRow     = data[data.length - 1];
    // lastDate        = new Date(lastDataRow[lastDataRow.length - 15]);
    // lastDate        = lastDate.toLocaleDateString('fr-FR');
    lastDate        = lastDataRow[lastDataRow.length - 15];
    lastDate        = new Date((lastDate - 25569) * 86400 * 1000);
    lastDate        = lastDate.toLocaleDateString('fr-FR');
  }

  return (
    <>
      <div className="vl-performances-page">
        <div
          style={{
            backgroundImage: `url(${process.env.REACT_APP_API_URI}/uploads/gif1_779783b6bf.gif)`,
            backgroundSize: "cover",
            backgroundPositionX: "center",
          }}
          className="vl-performances-titre-principal"
        >
          <div className="div-lottie-vl" id="react-logo-vl" />
          <div className="vl-performances-titre-principal-text">
            VL & Performances
          </div>
        </div>
        <div className="vl-performances-filtre-container-background">
          <div className="vl-performances-filtre-container">
            <div className="vl-performances-label-filtre">
              {/* PERIODE DE CALCUL DE LA PERFORMANCE{" "} */}
              Valeurs Liquidatives au {" "}
              {/* <strong>{frenchDate ? `${` ${frenchDate}`} ` : ""}</strong> */}
              <strong>{lastDate}</strong>
              
            </div>
            <div className="filter-periode-calcul">
              <div className="vl-performances-menu-deroulant">
                <MenusPerf
                  performanceKey={performanceKey}
                  setPerformanceKey={setPerformanceKey}
                  isDesktop={isDesktop}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="vl-performances-table-container-background">
          <div className="vl-performances-table-container">
            <div className="vl-performances-table-divider" />
            <div className="row-header">
              <div className="cell-header">OPCVM</div>
              <div className="cell-header">CLASSIFICATION</div>
              <div className="cell-header">ACTIF NET (MAD)</div>
              <div className="cell-header">VALEUR LIQUIDATIVE</div>
              <div className="cell-header">PERFORMANCE</div>
            </div>
            {!dataFormated && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: 10,
                }}
              >
                <CircularProgress />
              </Box>
            )}
            {dataFormated?.map((dataEl, index) => (
              <div key={index} className="row-data">
                <div className="cell-data">{dataEl.opcvm}</div>
                <div className="cell-data-2">{dataEl.classification}</div>
                <div className="cell-data-chiffre">
                  <div className="cell-performance-text">
                      {/* {dataEl.an} */}
                      {formatNumber(dataEl.an)}
                      {/* {dataEl.an.replace(/,/g, ' ').replace('.', ',')} */}
                  </div>
                </div>
                <div className="cell-data-chiffre">
                  <div className="cell-performance-text">
                    {formatNumber(dataEl.vl)}
                  </div>
                </div>
                <div className="cell-data">
                  <div className="cell-performance-value">
                    <div className="cell-performance-text">
                      {isNaN(dataEl[performanceKey.key])
                        ? "-"
                        : performanceKey.key === "unJour"
                        ? parseFloat(dataEl[performanceKey.key]).toFixed(3) +
                          "%"
                        : parseFloat(dataEl[performanceKey.key]).toFixed(2) +
                          "%"}
                      {/* {convertToPercentage(dataEl[performanceKey.key])} */}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
