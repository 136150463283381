import React, { useEffect, useState } from 'react';

import { bool, object } from 'prop-types';

import './KpisPage.scoped.scss';

KpisPage.propTypes = {
  isInViewKpis: bool,
  isDesktop: bool,
  contentData: object
};

export function KpisPage({ isInViewKpis, isDesktop, contentData }) {
  const maxCroissance = contentData?.kpisList[0][0].replace(/[^0-9]/g, '');
  const maxActif = contentData?.titleL11?.replace(/[^0-9]/g, '');
  const maxProgression = contentData?.kpisList[2][0].replace(/[^0-9]/g, '');
  const textProgression = contentData?.kpisList[2][0].replace(/[\d+]/g, '');
  const maxOpcvm = contentData?.kpisList[3][0].replace(/[^0-9]/g, '');
  const maxClients = contentData?.kpisList[1][0].replace(/[^0-9]/g, '');
  const [chiffreCroissance, setChiffreCroissance] = useState(0);
  const [chiffreActif, setChiffreActif] = useState(0);
  const [chiffreProgression, setChiffreProgression] = useState(0);
  const [chiffreOpcvm, setChiffreOpcvm] = useState(0);
  const [chiffreClients, setChiffreClients] = useState(0);
  useEffect(() => {
    if (isInViewKpis) {
      if (chiffreCroissance < maxCroissance) {
        setTimeout(() => setChiffreCroissance(chiffreCroissance + 1), 50);
      }
      if (chiffreActif < maxActif) {
        setTimeout(() => setChiffreActif(chiffreActif + 1), 300);
      }
      if (chiffreProgression < maxProgression) {
        setTimeout(() => setChiffreProgression(chiffreProgression + 1), 400);
      }
      if (chiffreOpcvm < maxOpcvm) {
        setTimeout(() => setChiffreOpcvm(chiffreOpcvm + 1), 250);
      }
      if (chiffreClients < maxClients) {
        setTimeout(() => setChiffreClients(chiffreClients + 1), 40);
      }
    } else {
      setChiffreCroissance(0);
      setChiffreActif(0);
      setChiffreProgression(0);
      setChiffreOpcvm(0);
      setChiffreClients(0);
    }
  }, [
    chiffreActif,
    chiffreClients,
    chiffreCroissance,
    chiffreOpcvm,
    chiffreProgression,
    isDesktop,
    isInViewKpis
  ]);
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${process.env.REACT_APP_API_URI}/uploads/ANIMATION_Home_Page_2eabd0fc6e.gif)`,
          backgroundSize: 'cover',
          padding: 0,
          margin: 0
        }}
        className="section-kpis">
        <div className="div-lottie-kpis" id="react-logo" />
        <div
          className="section-kpis-container"
          style={{
            display: isDesktop ? 'flex' : 'block'
          }}>
          <div
            style={{
              transform: isInViewKpis ? 'translate(0vw, 0vh)' : 'translate(-10vw, 0vh)',
              transitionDuration: '1s'
            }}
            className="side-chiffres-left">
            <div className="title-capitale">
              <div>
                <span className="chiffre-5">{`+${chiffreActif}`}</span> {contentData?.titleL12}
              </div>
              <div className="chiffre-5-sub-g">
                <div>{contentData?.titleL2}&nbsp;</div>
                <div>{contentData?.titleL3}</div>
              </div>
              <div className="rounded-capitale" />
            </div>
          </div>
          <div
            style={{
              transform: isInViewKpis ? 'translate(0vw, 0vh)' : 'translate(10vw, 0vh)',
              transitionDuration: '1s'
            }}
            className="side-chiffres">
            <div className="stats-row">
              <div className="stat-chiffre">{`+${chiffreCroissance}%`}</div>
              <div className="stat-definition">
                <div className="stat-definition-title">{contentData?.kpisList[0][1]}</div>
                <div className="stat-definition-body">{contentData?.kpisList[0][2]}</div>
              </div>
            </div>
            <div className="stats-row">
              <div className="stat-chiffre">{`+${chiffreClients}`}</div>
              <div className="stat-definition">
                <div className="stat-definition-title">{contentData?.kpisList[1][1]}</div>
                <div className="stat-definition-body">{contentData?.kpisList[1][2]}</div>
              </div>
            </div>
            <div className="stats-row">
              <div className="stat-chiffre">
                {`+${chiffreProgression}`}

                <span className="stat-mrd">{textProgression}</span>
              </div>

              <div className="stat-definition">
                <div className="stat-definition-title">{contentData?.kpisList[2][1]}</div>
                <div className="stat-definition-body">{contentData?.kpisList[2][2]}</div>
              </div>
            </div>
            <div className="last-stats-row">
              <div className="stat-chiffre">{`+${chiffreOpcvm}`}</div>
              <div className="stat-definition">
                <div className="stat-definition-title">{contentData?.kpisList[3][1]}</div>
                <div className="stat-definition-body">{contentData?.kpisList[3][2]}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
