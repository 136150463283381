import React from "react";

import { bool } from "prop-types";

import { GammeOpcvmPage } from "../performances/section-4-gamme/GammeOpcvmPage";
import { Presentation } from "./section-1-presentation/Presentation";

import "./NosFonds.scoped.scss";

NosFonds.propTypes = {
  isDesktop: bool,
};

export function NosFonds({ isDesktop }) {
  return (
    <>
      <div>
        <div className="nos-fond-page">
          <Presentation />
          <div style={{ height: isDesktop && "4200px" }}>
            <div style={{ position: "sticky", top: 0 }}>
              <GammeOpcvmPage
                isDesktop={isDesktop}
                isInViewGamme={true}
                isNosFonds={isDesktop}
              />
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
