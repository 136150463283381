import React, { useRef } from 'react';

import './Presentation.scoped.scss';
import { object } from 'prop-types';

Presentation.propTypes = {
  contentData: object
};

export function Presentation({ contentData }) {
  const ref = useRef(null);
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${process.env.REACT_APP_API_URI}/uploads/notre_expertise_d505783d3f.gif)`,
          backgroundSize: 'cover',
          backgroundPositionX: 'center'
        }}
        className="section-question">
        <div ref={ref} className="div-lottie" id="react-logo" />
        <div className="section-question-text">
          <div>{contentData?.titleL1.title11}</div>
          <div>{contentData?.titleL1.title12}</div>
        </div>
      </div>
      <div className="section-presentation-performances">
        <div className="section-presentation-performances-container">
          <div className="section-presentation-performances-text">
            {contentData?.titleL1.title2}
          </div>
          <div className="divider-presentation-performances" />
          <div className="presentation-performances-paragraph">
            {contentData?.titleL1?.pargraph1.map((par, index) => {
              return par === '' ? (
                <br key={index} />
              ) : (
                <div key={index}>
                  {par}
                  <br key={index} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
