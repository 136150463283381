import React, { useEffect, useRef, useState } from 'react';

import { bool, func, string } from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';

import { IconButton, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Transition } from 'react-transition-group';

import './ResponsiveAppBar.scoped.scss';

const duration = 500;

const defaultStyle = {
  transition: `all ${duration}ms ease-in-out`,
  opacity: 0,
  transform: 'rotate(90deg)',
  position: 'absolute'
};

const transitionStyles = {
  entering: { opacity: 1, transform: 'translate(0, 0)' },
  entered: { opacity: 1, transform: 'translate(0vw, 0)' },
  exiting: { opacity: 0, transform: 'rotate(90deg)' },
  exited: { opacity: 0, transform: 'rotate(90deg)' }
};

const defaultStyleMenuItems = {
  transition: `all ${duration}ms ease-in-out`,
  opacity: 0
};

const transitionStylesMenuItems = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 }
};

ResponsiveAppBar.propTypes = {
  isFirstSectionHome: bool,
  isSticky: bool,
  isDesktop: bool,
  isAuthenticated: bool,
  handleLogout: func,
  username: string
};

export function ResponsiveAppBar({
  isFirstSectionHome,
  isSticky,
  isDesktop,
  isAuthenticated,
  handleLogout,
  username
}) {
  const [showAppBar, setShowAppBar] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [open, setOpen] = useState(false);
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    if (open) {
      setTimeout(() => setDisplay(true), 500);
    }
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const show = prevScrollPos > currentScrollPos || currentScrollPos < 10;
      setShowAppBar(show);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [open, prevScrollPos]);

  if (open === false && display === true) {
    setDisplay(false);
  }

  const handleClose = () => {
    setOpen(false);
    setDisplay(false);
  };

  const navigate = useNavigate();
  const onClickNousConnaitre = () => navigate('/nous-connaitre');
  const onClickNotreEquipe = () => navigate('/notre-equipe');
  const onClickNosFonds = () => navigate('/nos-fonds');
  const onClickNotreExpertise = () => navigate('/notre-expertise');
  const onClickNousContacter = () => navigate('/contact-us');
  const onClickNosPublications = () => window.location.href = 'https://afgassetmanagement.com/espaceclients/publications/';
  const onClickVl = () => navigate('/vl-performances');
  const onClickEspaceClient = () => window.location.href = 'https://afgassetmanagement.com/espaceclients';

  const onClickLogo = () => navigate('/');

  const nodeRef = useRef(null);

  const nodeRef2 = useRef(null);

  const nodeRef3 = useRef(null);

  const appBarHeight = isDesktop ? '14.722222222222221vh' : '105px';
  const maxHeightLogo = isDesktop ? '6.481481481481481vh' : '44px';
  const maxWidthLogo = isDesktop ? '17.395833333333336vw' : '170px';
  const maxHeightLogoOpen = isDesktop ? '4.0740740740740735vh' : '44px';
  const maxWidthLogoOpen = isDesktop ? '11.041666666666668vw' : '170px';
  const maxHeightLogoClose = isDesktop ? '6.481481481481481vh' : '44px';
  const maxWidthLogoClose = isDesktop ? '17.395833333333336vw' : '170px';

  return (
    <div
      style={{
        height: open ? '100vh' : appBarHeight,
        backgroundImage:
          open && `url(${process.env.REACT_APP_API_URI}/uploads/animation_menu_act_8be8697677.gif)`,
        backgroundColor: isFirstSectionHome && !open && isDesktop ? 'transparent' : 'white',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: open ? ' 0 0' : ' 0 200px',
        top: 0,
        left: 0,
        width: '100vw',
        justifyContent: 'center',
        transitionDuration: '1.5s',
        boxShadow:
          isFirstSectionHome && isDesktop
            ? 'unset'
            : '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
        position: isDesktop || showAppBar || open ? (isSticky ? 'sticky' : 'absolute') : 'relative',
        zIndex: 1001,
        overflow: 'hidden'
      }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          height: isDesktop ? '14.7222vh' : '105px'
        }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            maxHeight: '14.7222vh'
          }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '80vw',
              height: '6.481481481481481vh',
              alignItems: 'center'
            }}>
            {isFirstSectionHome && isDesktop && !open ? (
              <div
                onClick={onClickLogo}
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
                className="logo-1">
                <img
                  className="fit-picture"
                  src={`${process.env.REACT_APP_API_URI}/uploads/logo_blanc_22e33625db.png`}
                  alt="logo-blanc"
                  style={{
                    maxHeight: maxHeightLogo,
                    maxWidth: maxWidthLogo,
                    cursor: 'pointer'
                  }}
                />
              </div>
            ) : (
              <div
                onClick={onClickLogo}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                className="logo-1">
                <img
                  className="fit-picture"
                  src={`${process.env.REACT_APP_API_URI}/uploads/logo_bleu_8098dc6a08.png`}
                  alt="logo-bleu"
                  style={{
                    maxHeight: open ? maxHeightLogoOpen : maxHeightLogoClose,
                    maxWidth: open ? maxWidthLogoOpen : maxWidthLogoClose,
                    transitionDuration: '1s',
                    cursor: 'pointer'
                  }}
                />
                {isDesktop && (
                  <>
                    <div
                      className="menu-logo-divider"
                      style={{
                        height: '22px',
                        borderRight: '1px solid black',
                        marginLeft: '63px',
                        opacity: open ? 1 : 0,
                        transitionDuration: '1s'
                      }}
                    />
                    <div
                      style={{
                        opacity: open ? 1 : 0,
                        transitionDuration: '1s'
                      }}
                      className="menu-indic">
                      menu
                    </div>
                  </>
                )}
              </div>
            )}
            <div style={{ display: 'flex', height: '9.25925925925926vh', alignItems: 'center' }}>
              {isDesktop && (
                <div
                  onClick={onClickEspaceClient}
                  style={{
                    width: open ? '160px' : '150px',
                    font:
                      'normal normal 300 ' +
                      (open ? '16px' : '14px') +
                      '/' +
                      (open ? '7vh' : '5vh') +
                      ' Aileron-Regular',
                    border: '1px solid #282E49',
                    height: open ? '7vh' : '5vh',
                    borderRadius: '26px',

                    textTransform: 'uppercase',
                    color: '#282E49',
                    backgroundColor: 'white',
                    cursor: 'pointer',
                    transitionDuration: '1s',
                    opacity: isDesktop && (open || !isFirstSectionHome) ? 1 : 0
                  }}>
                  espace privé
                </div>
              )}
              {isAuthenticated && (
                <div>
                  <Tooltip title="Se déconnecter">
                    <IconButton onClick={handleLogout}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: isDesktop ? 'column-reverse' : 'column-reverse',
                          alignItems: 'center'
                        }}>
                        <div
                          style={{
                            fontSize: '14px',
                            marginRight: isDesktop ? '10px' : 'unset'
                          }}>
                          {username}
                        </div>
                        <div>
                          <LogoutIcon
                            sx={{
                              color: isFirstSectionHome && isDesktop && !open ? 'white' : 'black',
                              verticalAlign: 'middle'
                            }}
                            fontSize="large"
                          />
                        </div>
                      </div>
                    </IconButton>
                  </Tooltip>
                </div>
              )}

              <div
                style={{
                  lineHeight: '6.481481481481481vh',
                  cursor: 'pointer',
                  width: '51px',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center'
                }}>
                <Transition
                  in={!open}
                  timeout={duration}
                  unmountOnExit
                  mountOnEnter
                  nodeRef={nodeRef}>
                  {(state) => (
                    <div
                      ref={nodeRef}
                      style={{
                        ...defaultStyle,
                        ...transitionStyles[state]
                      }}>
                      <IconButton onClick={() => setOpen(true)}>
                        <MenuIcon
                          sx={{
                            color: isFirstSectionHome && isDesktop && !open ? 'white' : 'black',
                            verticalAlign: 'middle'
                          }}
                          fontSize="large"
                        />
                      </IconButton>
                    </div>
                  )}
                </Transition>
                <Transition
                  in={open}
                  timeout={duration}
                  unmountOnExit
                  mountOnEnter
                  nodeRef={nodeRef2}>
                  {(state) => (
                    <div
                      ref={nodeRef2}
                      style={{
                        ...defaultStyle,
                        ...transitionStyles[state]
                      }}>
                      <IconButton onClick={() => handleClose(false)}>
                        <CloseIcon
                          fontSize="large"
                          sx={{
                            verticalAlign: 'middle'
                          }}
                        />
                      </IconButton>
                    </div>
                  )}
                </Transition>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Transition in={open} timeout={duration} unmountOnExit mountOnEnter nodeRef={nodeRef3}>
        {(state) => (
          <div
            ref={nodeRef3}
            style={{
              ...defaultStyleMenuItems,
              ...transitionStylesMenuItems[state],
              display: 'flex',
              textAlign: 'left',
              alignItems: !isDesktop && 'center',
              marginLeft: isDesktop && '22.5vw',
              justifyContent: 'left',
              flexDirection: 'column',
              transitionDuration: '2s',
              marginTop: isDesktop && '14.351851851851851vh'
            }}
            className="app-bar-menu-button-container">
            <div>
              {!isDesktop && (
                <div style={{ display: 'flex', alignItems: 'center', margin: '30px 0 30px 0' }}>
                  <div className="menu-indic-mobile">menu</div>
                  <div
                    style={{
                      height: '30px',
                      borderRight: '1px solid black',
                      margin: '0 10px 0 10px'
                    }}
                  />
                  <div
                    onClick={onClickEspaceClient}
                    style={{
                      width: '200px',
                      font: 'normal normal 300 12px/30px Aileron-Regular',
                      textAlign: 'center',
                      border: '1px solid #282E49',
                      height: '30px',
                      borderRadius: '26px',
                      textTransform: 'uppercase',
                      color: '#282E49',
                      backgroundColor: 'white',
                      cursor: 'pointer',
                      transitionDuration: '1s',
                      opacity: open || !isFirstSectionHome ? 1 : 0
                    }}>
                    espace privé
                  </div>
                </div>
              )}
              <div onClick={onClickNousConnaitre} className="app-bar-menu-button">
                Nous connaître
              </div>
              <div onClick={onClickNotreEquipe} className="app-bar-menu-button">
                Nos équipes
              </div>
              <div onClick={onClickNotreExpertise} className="app-bar-menu-button">
                Notre expertise
              </div>
              <div onClick={onClickNosFonds} className="app-bar-menu-button">
                Nos Fonds
              </div>
              <div onClick={onClickNosPublications} className="app-bar-menu-button">
                Nos publications
              </div>
              <div onClick={onClickNousContacter} className="app-bar-menu-button">
                Nous contacter
              </div>
              <div className="app-bar-menu-divider" />
              <div onClick={onClickVl} className="app-bar-menu-button-vl">
                VL & Performances
              </div>
            </div>
          </div>
        )}
      </Transition>
    </div>
  );
}
