/* eslint-disable max-len */
export const listeProfiles = [
  {
    id: 1,
    nom: 'Souhail Chalabi',
    poste: 'Directeur Général',
    numero: '+212.5.22.05.12.12',
    email: 'souhail.chalabi@afg-am.com',
    formation: [
      { ecole: 'HEC Paris', specialite: 'MSc, management' },
      { ecole: 'IAE-Sorbonne', specialite: 'DEA, management science' },
      {
        ecole: 'Université de Casablanca',
        specialite: 'MA, operations research'
      }
    ],
    description: [
      'Souhail Chalabi dispose d’une expérience de 22 ans sur les marchés financiers dont 19 ans en gestion d’actifs.',
      'Souhail a débuté sa carrière en 2001 à CFG Group en tant qu’analyste financier avant de rejoindre la filiale' +
        ' Gestion d’Actifs de la Société Générale en 2003 en qualité de Gérant de Portefeuilles.',
      'En 2006, Souhail rejoint BMCE Capital Gestion, où il occupe le poste de Responsable de la Gestion ' +
        'Taux puis de Directeur des Gestions. Il y dirige et développe les activités Gestions Taux, Actions, ' +
        'Multi-Assets et l’Analyse Buy-Side pour un périmètre couvrant 44 fonds et plus de 50 MrdDH d’actifs sous gestion.',
      'En 2017, Souhail devient Directeur Général Adjoint de BMCE Capital Gestion et dirige les activités ' +
        'gestion et commerciales sur les segments Institutionnel, Corporate et Retail. Sous sa direction, les ' +
        'actifs sous gestion ont triplé en 10 ans, passant de 24 MrdDH à 75 MrdDH à fin 2021.',
      'En 2021, Souhail rejoint le Groupe AFG pour lancer l’activité Gestion d’Actifs pour compte de tiers au ' +
        'sein du Groupe. Il co-fonde aux côtés du Président du Groupe, AFG Asset Management, première société ' +
        'de gestion de type entrepreneurial au Maroc.',
      'Souhail est de formation ingénieur, diplômé de l’Ecole Hassania des Travaux Publics. Il est également ' +
        'titulaire de l’Exécutive MBA de l’Ecole Nationale des Ponts et Chaussées.'
    ],
    pathImg: `${process.env.REACT_APP_API_URI}/uploads/souhail_46c0a1d296.jpg`
  },
  {
    id: 2,
    nom: 'Kenza El Adlouni',
    poste: 'Directeur de Gestion',
    numero: '+212.5.22.05.12.12',
    email: 'kenza.eladlouni@afg-am.com',
    formation: [
      { ecole: 'HEC Paris', specialite: 'MSc, management' },
      { ecole: 'IAE-Sorbonne', specialite: 'DEA, management science' },
      {
        ecole: 'Université de Casablanca',
        specialite: 'MA, operations research'
      }
    ],
    description: [
      'Kenza Dispose de 11 ans d’expérience en gestion d’actifs.',
      'Kenza a débuté sa carrière en 2012 en tant que Gérant de Portefeuille au sein de BMCE Capital Gestion ' +
        'Sous Mandat. En 2015, Kenza rejoint BMCE Capital Gestion en qualité de gérant OPCVM. Elle y gère des Fonds ' +
        'Grand Public et Dédiés pour un encours moyen de 15 Mrdh couvrant toutes les classes d’actifs, Monétaire, ' +
        'OCT, OMLT et Diversifié.',
      'En 2021, Kenza rejoint AFG Asset Management pour contribuer au lancement de la nouvelle société de gestion' +
        '« AFG Asset Management » en tant que Directeur des Gestions. Elle y met en place les process clés ' +
        'relatifs au Front Office et contribue activement à la redéfinition et aux calibrages des stratégies ' +
        'd’investissement et de gestion des risques des Fonds AFG Asset Management. Son périmètre de gestion ' +
        'couvre 6 Fonds pour un total actif atteignant les 5 MrdDH en 2022 après un démarrage à 520 MDH en juin 2021.',
      'Kenza est de formation ingénieur, diplômée de l’Ecole Mohammadia d’Ingénieurs. Elle est également ' +
        'titulaire du Master Spécialisé Techniques Financières de l’ESSEC Business School.'
    ],
    pathImg: `${process.env.REACT_APP_API_URI}/uploads/kenza_2b07cc45a5.jpg`
  },
  {
    id: 3,
    nom: 'Ghassane Sabar',
    poste: 'Gérant Senior',
    numero: '+212.5.22.05.12.12',
    email: 'ghassane.sabar@afg-am.com',
    formation: [
      { ecole: 'HEC Paris', specialite: 'MSc, management' },
      { ecole: 'IAE-Sorbonne', specialite: 'DEA, management science' },
      {
        ecole: 'Université de Casablanca',
        specialite: 'MA, operations research'
      }
    ],
    description: [
      'Ghassane dispose de plus de 9 ans d’expérience sur les marchés financiers dont 5 en gestion d’actifs.',
      'Ghassane a débuté sa carrière en 2014 à Bank Al Maghrib en tant qu’auditeur interne en charge de l’audit de la ' +
        'politique monétaire, de la gestion des réserves de change et de la supervision Micro et Macro prudentielle. Il a  ' +
        'également été en charge de l’élaboration de notes d’analyse pour le Conseil Monétaire et Financier de la Banque Centrale',
      'En 2017, Ghassane rejoint Sogécapital Gestion, filiale de Société Générale Maroc, en tant qu’analyste Buy-Side avant ' +
        'd’être nommé Gérant de Portefeuilles. Il a participé à la refonte de l’approche d’investissement en Actions et a ' +
        'également contribué au lancement de plusieurs OPCVM Actions et Diversifiés.',
      'En 2021, Ghassane rejoint AFG Asset Management en qualité de Gérant Senior en charge de la Gestion Actions & Diversifiés.',
      'Ghassane est titulaire du Master Grande Ecole majeure Finance de Marché de Neoma Business School et du ' +
        'Master of Science - Ingénierie Financière - de l’EM LYON Business School.'
    ],
    pathImg: `${process.env.REACT_APP_API_URI}/uploads/ghassane_5ac699ba69.jpg`
  },
  {
    id: 4,
    nom: 'Saad EL MALIYANI',
    poste: 'Gérant Taux',
    numero: '+212.5.22.05.12.12',
    email: 'saad.elmaliyani@afg-am.com',
    formation: [
      { ecole: 'HEC Paris', specialite: 'MSc, management' },
      { ecole: 'IAE-Sorbonne', specialite: 'DEA, management science' },
      {
        ecole: 'Université de Casablanca',
        specialite: 'MA, operations research'
      }
    ],
    description: [
      'Saad dispose de 5 ans d’expérience sur les marchés financiers dont 3 en gestion d’actifs.',
      'Saad a débuté sa carrière en 2019 à Londres au sein d’ING Wholesale Banking en tant qu’assistant trader sur le desk trading dérivés de taux. Il a participé au processus exécutif des opérations sur le marché et a été en charge de la mise en place de nouveaux outils liés au développement de l’activité notamment des pricers, des rapports de suivi de risques et de calcul de P&L.',      
      'En 2020, Saad a rejoint IRG Asset Management en qualité de gérant taux en charge de la gestion des Fonds Grand Public monétaires et OCT pour un encours moyen de 3,5 Mrdh.',
      'En 2022 Saad rejoint AFG Asset Management en tant que gérant Taux en charge de la gestion obligataire.',
      'Saad est titulaire du Master Finance de Toulouse School Of Economics et du Master Grande Ecole majeure Finance de Marché de Skema Business School.',
    ],
    pathImg: `${process.env.REACT_APP_API_URI}/uploads/Saad_97e0dca481.jpg`
  },
 {
    id: 5,
    nom: 'Anass BARIGO"',
    poste: ' Gérant Actions & Diversifiés',
    numero: '+212.5.22.05.12.12',
    email: 'anass.barigo@afg-am.com',
    formation: [
      { ecole: 'Université Paris XIII', specialite: 'Maitrise AES' },
      { ecole: 'IAE-Sorbonne', specialite: 'DEA, management science' },
      {
        ecole: 'Université de Casablanca',
        specialite: 'MA, operations research'
      }
    ],
    description: [
      'Anass dispose de 8 ans d’expérience en gestion d’actifs.',
    'Anass a démarré sa carrière en 2016 au sein d’OBAFRICA AM en tant qu’analyste financier Buy-Side, il y a accompagné le lancement de l’activité Gestion de Portefeuille Actions et a participé activement à la mise en place du département Analyse & Recherche Buy-Side. En 5 ans, Anass a réussi à développer son expertise en analyse fondamentale Equity, en Stock Picking et en conception de stratégies d’investissement Equity sur le marché boursier marocain ainsi que sur les principales places boursières africaines. ',
    'En 2021, Anass rejoint BMCE Capital Gestion en tant que Gérant Actions & Diversifiés où il contribue à la conception et au dépoilement de stratégies d’investissement Actions sur Fonds Grand Public et dédiés pour un encours moyen sous gestion de 5 Mrdh.',
    'En 2024, Anass rejoint AFG Asset Management en qualité de Gérant Actions & Diversifiés.',
    'Anass est titulaire d’un Master en Gestion Financière et Comptable de l’Ecole Nationale de Commerce et de Gestion de Casablanca.'
  ],
    pathImg: `${process.env.REACT_APP_API_URI}/uploads/Anass_BARIGO_cde5fd4a16.jpg`
  },
  {
    id:6,
    nom: 'Nisrine Adel',
    poste: 'Responsable Contrôle Interne',
    numero: '+212.5.22.05.12.12',
    email: 'nisrine.adel@afg-am.com',
    formation: [
      { ecole: 'HEC Paris', specialite: 'MSc, management' },
      { ecole: 'IAE-Sorbonne', specialite: 'DEA, management science' },
      {
        ecole: 'Université de Casablanca',
        specialite: 'MA, operations research'
      }
    ],
    description: [
      'Nisrine Adel dispose d’une expérience de 17 ans en matière de Contrôle Interne OPCVM, conformité et gestion des' +
        'risques inhérents à la gestion d’OPCVM. Elle est habilitée par l’AMMC pour l’exercice de la fonction de contrôle interne OPCVM',
      'Nisrine a débuté son parcours en Contrôle Interne en 2006 au sein de la société de gestion Upline Capital Management avant de rejoindre en 2009 Atlas ' +
        'Capital Management en tant que Contrôleur Interne puis Contrôleur Interne Senior.',
      'En 2020, Nisrine intègre BMCE Capital Gestion en qualité de Responsable Contrôle Interne et passe avec ' +
        'succès son examen d’habilitation AMMC des fonctions de contrôleur interne OPCVM en 2021.',
      'En 2022, Nisrine rejoint AFG Asset Management afin de contribuer au renforcement du dispositif de ' +
        'Contrôle Interne et de gestion des risques au sein de la société de gestion.',
      'Nisrine est diplômée de l’Institut Supérieur de Commerce et de Gestion - Casablanca.'
    ],
    pathImg: `${process.env.REACT_APP_API_URI}/uploads/nisrine_cf79bf0903.jpg`
  },
  {
    id: 7,
    nom: 'Loutfi El Moutaouakil',
    poste: 'Responsable Middle-Office',
    numero: '+212.5.22.05.12.12',
    email: 'loutfi.elmoutaouakil@afg-am.com',
    formation: [
      { ecole: 'HEC Paris', specialite: 'MSc, management' },
      { ecole: 'IAE-Sorbonne', specialite: 'DEA, management science' },
      {
        ecole: 'Université de Casablanca',
        specialite: 'MA, operations research'
      }
    ],
    description: [
      'Loutfi El Moutaouakil cumule une expérience de 14 ans en Gestion d’Actifs sur les périmètres opérationnels Middle/Back Office, notamment la gestion des opérations Actif/Passif, la valorisation des portefeuilles et la gestion des risques opérationnels et réglementaires.',
      'Elle a rejoint AFG Asset Management en avril 2021.',
      'Il a débuté son parcours professionnel en 2009 à FINERGY puis à M.S.IN où il était en charge des activités Back Office Actions avant de rejoindre Capital Trust Gestion en 2011 en tant que Responsable Back-Office puis Responsable Middle-Office. ',
      'En 2016, il rejoint CDG Capital Gestion où il était en charge des activités Middle-Office et Reportings',
      'Loutfi est titulaire d’une Licence en sciences économiques et gestion de l’université Moulay Ismail de Meknès et d’un Master en sciences économiques et gestion, option Monnaie, Banque et finance. Il prépare son doctorat en sciences économiques sous le thème ‘‘Gestion des risques de liquidité pour les OPCVM au Maroc’’'
    ],
    pathImg: `${process.env.REACT_APP_API_URI}/uploads/loutfi_7525c2db99.jpg`
  },
  {
    id: 8,
    nom: 'Mouad Fethallah',
    poste: 'Responsable Back-Office',
    numero: '+212.5.22.05.12.12',
    email: 'mouad.fethallah@afg-am.com',
    formation: [
      { ecole: 'HEC Paris', specialite: 'MSc, management' },
      { ecole: 'IAE-Sorbonne', specialite: 'DEA, management science' },
      {
        ecole: 'Université de Casablanca',
        specialite: 'MA, operations research'
      }
    ],
    description: [
      'Avant de rejoindre AFG Asset Management, Mouad a démarré son parcours professionnel en 2020 en tant que cadre Back-Office au sein de BMCE Capital Solutions. Il était en charge du traitement comptable des opérations Actif/Passif des OPCVM, des rapprochements OPCVM ainsi que des Reportings réglementaires AMMC',
      'Mouad est diplômé de l’École Nationale de Commerce et de Gestion de Settat – Option Audit et Contrôle de gestion.'
    ],
    pathImg: `${process.env.REACT_APP_API_URI}/uploads/mouad_cbb6f4f680.jpg`
  },
  {
    id: 9,
    nom: 'Houda NAJIM',
    poste: 'Office Manager',
    numero: '+212.5.22.05.12.12',
    email: 'yousra.kalakhi@afg-am.com',
    formation: [
      { ecole: 'HEC Paris', specialite: 'MSc, management' },
      { ecole: 'IAE-Sorbonne', specialite: 'DEA, management science' },
      {
        ecole: 'Université de Casablanca',
        specialite: 'MA, operations research'
      }
    ],
    description: [
      'Houda dispose d’une expérience de 5 ans dans les métiers post-marché en banque d’investissement. Elle a démarré son parcours professionnel en 2019 au sein de Société Générale Maroc en qualité de cadre post-marché au sein du Département Titres Banque d’Investissement. Elle y a occupé plusieurs postes dont celui de chargée de contrôle Dépositaire OPCVM, chargée de service aux émetteurs et enfin, chargée de Gré à Gré et OPC. Ses missions incluaient le traitement, le contrôle de conformité, la comptabilisation et le dénouement des opérations de marché.',
   
      'Houda est diplômée de l’École Nationale de Commerce et de Gestion de Casablanca - spécialité Actuariat et Finance. En mai 2022, elle a passé avec succès l’examen d’habilitation de l’AMMC des professionnels du marché des capitaux pour l’exercice des fonctions post-marché.'
    ],
    pathImg: `${process.env.REACT_APP_API_URI}/uploads/Houda_NAJIM_9be3aa2214.jpg`
  },
  {
    id: 10,
    nom: 'Yousra Kalakhi',
    poste: 'Office Manager',
    numero: '+212.5.22.05.12.12',
    email: 'yousra.kalakhi@afg-am.com',
    formation: [
      { ecole: 'HEC Paris', specialite: 'MSc, management' },
      { ecole: 'IAE-Sorbonne', specialite: 'DEA, management science' },
      {
        ecole: 'Université de Casablanca',
        specialite: 'MA, operations research'
      }
    ],
    description: [
      'Yousra a rejoint l’équipe AFG Asset Management en 2021 en tant qu’Office Manager.',
      'De 2018 à 2021, Yousra a occupé le poste d’Assistante de Direction à BMCE Capital Gestion. Auparavant, elle a été Assistante de Direction au sein du cabinet d’avocats international Bennani & Associés.',
      'Yousra est titulaire d’un Diplôme d’Etudes Universitaires Générales, option Economie & Gestion des Entreprises de l’Université Hassan II à Casablanca.'
    ],
    pathImg: `${process.env.REACT_APP_API_URI}/uploads/yousra_490f47ba74.jpg`
  },
  {
    id: 11,
    nom: 'Kamel El Khalfet"',
    poste: 'Responsable Commercial',
    numero: '+212.5.22.05.12.12',
    email: 'kamel.elkhalfet@afg-am.com',
    formation: [
      { ecole: 'Université Paris XIII', specialite: 'Maitrise AES' },
      { ecole: 'IAE-Sorbonne', specialite: 'DEA, management science' },
      {
        ecole: 'Université de Casablanca',
        specialite: 'MA, operations research'
      }
    ],
    description: [
      'Kamel Dispose de 25 ans d’expérience en gestion d’actifs dans les activités de conseil en placement et développement commercial.',
    'Kamel a débuté sa carrière en 1998 en rejoignant l’équipe commerciale de Wafa Gestion. Il y occupe au fil du temps plusieurs fonctions au sein de la Direction Commerciale. Au sein de Wafa Gestion, Kamel a notamment été en charge de l’animation et la formation du réseau bancaire, la gestion patrimoniale ainsi la gestion d’un portefeuille de clients Institutionnels et Grandes Entreprises.',
    'En 2002, Kamel rejoint CDG Capital Gestion (ex CD2G) en tant que responsable Commercial. Il y contribue activement à développer les encours sous gestion et à mettre en place la Direction Commerciale.',
    'En 2023, Kamel rejoint AFG Asset Management en tant que Responsable Commercial pour y a accompagner le développement du portefeuille et la gestion de la relation client, notamment en matière de conseil en placement.',
    'Kamel est de formation universitaire, diplômée de l’Université Paris XIII, d’une maitrise AES. Il est titulaire de l’habilitation professionnelle de l’AMMC pour exercer la fonction de Conseiller Financier.'
  ],
    pathImg: `${process.env.REACT_APP_API_URI}/uploads/kamel_673ca5428d.jpg`
  },
];
