import React, { useEffect, useRef, useState } from 'react';

import { useMediaQuery } from '@mui/material';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { AfgMonetaire } from './components/afg-monetaire/AfgMonetaire';
import { NousContacter } from './components/contact-us/NousContacter';
import { Inscription } from './components/inscription/Inscription';
import { NosFonds } from './components/nos-fonds/NosFonds';
import { NotreEquipe } from './components/notre-equipe/NotreEquipe';
import { Performances } from './components/performances/Performances';
import { TestScroll } from './components/TestScroll';
import { VlPerformances } from './components/vl-performance/VlPerformances';
import { HomePage } from './home/HomePage';
import 'typeface-montserrat';
import './App.scoped.scss';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { NosPublications } from './components/nos-publications/NosPublications';
import { EspaceClient } from './components/espace-client/EspaceClient';
import { MobilePortrait } from './MobilePortrait';
import { MentionsLegales } from './components/mentions-legales/MentionsLegales';
import axios from 'axios';
import { SeConnecter } from './components/espace-client/SeConnecter';
import { ResponsiveAppBar } from './components/common/ResponsiveAppBar';
import { Section } from './components/Section';
import { FooterHome } from './components/common/footer/FooterHome';
import { useIsInViewport } from './components/afg-monetaire/section-3-first-2-squars/FcpManagementSquars1';
import CookieConsent, { OPTIONS } from 'react-cookie-consent';
import { ChangePassword } from './components/espace-client/ChangePassword';
import { InactivityLogout } from './InactivityLogout';


const stickyRoutes = [
  '/contact-us',
  '/login',
  '/espace-client',
  '/mentions-legales',
  '/nos-fonds',
  '/mentions-legales',
  '/nos-publications',
  '/notre-equipe',
  '/vl-performances'
];

export function App() {
  const isDesktop = useMediaQuery('(min-width: 767px)');

  const [showAnimationDepart, setShowAnimationDepart] = useState(true);
  const [isFirstSectionHome, setIsFirstSectionHome] = useState(false);
  const [username, setUserName] = useState('');
  const [showWrongPassword, setShowWrongPassword] = useState('');
  const [showUserBlocked, setShowUserBlocked] = useState(false);

  const [displayFooter, setDisplayFooter] = useState(false);

  const [isAppBarSticky, setIsAppBarSticky] = useState(false);
  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia('(orientation: portrait)').matches
  );

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // Do something when the element becomes visible
        setIsFirstSectionHome(true);
      } else {
        setIsFirstSectionHome(false);
      }
    });
  });

  const targets = document.querySelectorAll('.transition-div-video');
  targets.forEach((target) => observer.observe(target));

  const refFooter = useRef();
  const isInViewFooterNoMediaQuery = useIsInViewport(refFooter);
  const isInViewFooter = !isDesktop || isInViewFooterNoMediaQuery;

  const footerComponent = (
    <Section id="section6">
      <div className="transition-div" ref={refFooter} />
      <FooterHome isInViewFooter={isInViewFooter} isDesktop={isDesktop} />
    </Section>
  );

  const location = useLocation();
  useEffect(() => {
    if (stickyRoutes.some((route) => location.pathname?.includes(route))) {
      setDisplayFooter(true);
      setIsAppBarSticky(true);
    } else {
      setDisplayFooter(false);
      setIsAppBarSticky(false);
    }
  }, [location]);
  useEffect(() => {
    if (showAnimationDepart) {
      setTimeout(() => setShowAnimationDepart(false), 4500);
    }
  }, [showAnimationDepart]);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    function handleOrientationChange(e) {
      setIsPortrait(e.matches);
    }

    const mediaQuery = window.matchMedia('(orientation: portrait)');
    mediaQuery.addEventListener('change', handleOrientationChange);
    const token = sessionStorage.getItem('token');
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      setIsAuthenticated(true);
    }
    return () => mediaQuery.removeEventListener('change', handleOrientationChange);
  }, [location, window]);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const navigate = useNavigate();

  const handleLogin = async (email, password) => {
    const options = {
      baseURL: process.env.REACT_APP_API_URI
    };
    try {
      const response = await axios.post(
        '/api/auth/local',
        { identifier: email, password },
        options
      );
      if (response.data.message) {
        setShowUserBlocked(true);
        return;
      }
      if (response.data?.user?.isFirstLogin) {
        navigate('/reset-password/' + email, {
          state: { currentPassword: password, jwt: response.data.jwt }
        });
      } else {
        const token = response.data.jwt;
        const username = response.data.user.username;
        sessionStorage.setItem('token', token);
        setUserName(username);
        setIsAuthenticated(true);
        setShowWrongPassword(false);

        return true;
      }
    } catch (error) {
      console.error('Login failed:', error);
      setShowWrongPassword(true);
      return false;
    }
  };
  const handleLogout = () => {
    sessionStorage.removeItem('token');    
    delete axios.defaults.headers.common['Authorization'];
    setIsAuthenticated(false);
  };
  const RequireAuth = () => {
    const token = sessionStorage.getItem('token');
    let location = useLocation();
    if (!token) return <Navigate to="/login" state={{ from: location }} />;
    return <Outlet />;
  };

  return isPortrait || !isMobile ? (
    <TransitionGroup component={null}>
      <CSSTransition key={location.key} classNames="fade" timeout={1000}>
        <div className="App">
          <div
            style={{
              height: '100vh',
              transitionDuration: '1s',
              opacity: showAnimationDepart ? 1 : 0,
              transform: showAnimationDepart ? 'translate(0, 0)' : 'translate(0, -100vh)',
              width: '100vw',
              position: 'absolute',
              backgroundImage: `url(${process.env.REACT_APP_API_URI}/${
                isDesktop
                  ? 'uploads/animation_depart_007eced996.gif'
                  : '/uploads/animation_logo_mobile_6e5127ca16.gif'
              })`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              zIndex: 1002
            }}
          />
          {isAuthenticated && <InactivityLogout handleLogout={handleLogout} />}
          <CookieConsent
            location={OPTIONS.BOTTOM}
            buttonText="Accepter & continuer"
            buttonClasses="btn btn-primary"
            containerClasses="alert alert-warning col-lg-12"
            contentClasses="text-capitalize"
            style={{
              backgroundColor: '#282e49',
              //flexFlow: isDesktop ? 'column wrap' : 'row wrap',
              //height: isDesktop ? '200px' : '300px',
              alignItems: 'center',
              justifyContent: 'center',
              //padding: isDesktop ? ' 0 200px 0 200px' : ' 0 30px 0 30px',
              //textAlign: isDesktop ? 'left' : 'center',
              //width: isDesktop ? 'calc(100vw - 400px)' : 'calc(100vw - 60px)',
              //flexDirection: isDesktop ? 'unset' : 'column',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              lineHeight: '30px',
              display: isDesktop ? 'flex' : 'block',
              zIndex: 99999
            }}
            buttonStyle={{
              backgroundColor: 'transparent',
              border: '1px solid #c1a367',
              color: '#FFF',
              margin: isDesktop ? '0 150px 0 0px' : '0 0 50px 0',
              fontWeight: '600',
              fontFamily: 'Montserrat',
              borderRadius: '4px',
              padding: '10px 20px 10px 20px'
            }}>
            <div
              style={{
                textAlign: isDesktop ? 'left' : 'center',
                width: 'fit-content',
                marginLeft: isDesktop ? '150px' : 0
              }}>
              Nous utilisons des cookies en vue d&apos;optimiser votre visite sur notre site.{' '}
              {isDesktop && <br />}
              En poursuivant votre navigation sur notre site web, vous acceptez l&apos;utilisation
              des cookies.
            </div>
          </CookieConsent>
          <ResponsiveAppBar
            isDesktop={isDesktop}
            isSticky={isAppBarSticky || !isDesktop}
            isFirstSectionHome={!isDesktop || isFirstSectionHome}
            isAuthenticated={isAuthenticated}
            handleLogout={handleLogout}
            username={username}
          />
          <Routes>
            {/* <Route
              path="/login"
              element={
                <SeConnecter
                  onLogin={handleLogin}
                  handleLogout={handleLogout}
                  isDesktop={isDesktop}
                  showWrongPassword={showWrongPassword}
                  showUserBlocked={showUserBlocked}
                />
              }
            /> */}
            {/* <Route
              path="/reset-password/:email"
              element={
                <ChangePassword
                  onLogin={handleLogin}
                  handleLogout={handleLogout}
                  isDesktop={isDesktop}
                />
              }
            /> */}
            {/* <Route element={<RequireAuth />}>
              <Route path="/espace-client" element={<EspaceClient isDesktop={isDesktop} />} />
            </Route> */}

            <Route
              exact
              path="/"
              element={
                <HomePage
                  footerComponent={footerComponent}
                  isDesktop={isDesktop}
                  isInViewVideo={isFirstSectionHome}
                />
              }
            />
            <Route
              exact
              path="/nous-connaitre"
              element={
                <HomePage
                  footerComponent={footerComponent}
                  scrollToNousConnaitre
                  isDesktop={isDesktop}
                  isInViewVideo={isFirstSectionHome}
                />
              }
            />
            <Route exact path="/contact-us" element={<NousContacter isDesktop={isDesktop} />} />
            <Route exact path="/inscription" element={<Inscription isDesktop={isDesktop} />} />
            <Route
              exact
              path="/notre-expertise"
              element={<Performances footerComponent={footerComponent} isDesktop={isDesktop} />}
            />
            <Route
              exact
              path="/notre-gamme/:id"
              element={<AfgMonetaire isDesktop={isDesktop} footerComponent={footerComponent} />}
            />
            <Route
              exact
              path="/nos-publications"
              element={
                <NosPublications
                  handleLogout={handleLogout}
                  isAuthenticated={isAuthenticated}
                  isDesktop={isDesktop}
                />
              }
            />
            <Route exact path="/test-scroll" element={<TestScroll />} />
            <Route
              exact
              path="/vl-performances"
              element={<VlPerformances isDesktop={isDesktop} />}
            />
            <Route exact path="/notre-equipe" element={<NotreEquipe isDesktop={isDesktop} />} />
            <Route exact path="/nos-fonds" element={<NosFonds isDesktop={isDesktop} />} />
            <Route exact path="/notre-equipe/:id" element={<NotreEquipe isDesktop={isDesktop} />} />
            <Route
              exact
              path="/mentions-legales"
              element={<MentionsLegales isDesktop={isDesktop} />}
            />
          </Routes>
          {displayFooter && footerComponent}
        </div>
      </CSSTransition>
    </TransitionGroup>
  ) : (
    <MobilePortrait />
  );
}
