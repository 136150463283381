import React from 'react';

import { bool } from 'prop-types';
import { useNavigate } from 'react-router-dom';

import './FooterHome.scoped.scss';

FooterHome.propTypes = {
  isInViewFooter: bool,
  isDesktop: bool
};

export function FooterHome({ isInViewFooter, isDesktop }) {
  const navigate = useNavigate();
  const onClickNousConnaitre = () => navigate('/nous-connaitre');
  const onClickNotreEquipe = () => navigate('/notre-equipe');
  const onClickNosFonds = () => navigate('/nos-fonds');
  const onClickNotreExpertise = () => navigate('/notre-expertise');
  const onClickNousContacter = () => navigate('/contact-us');
  const onClickVl = () => navigate('/vl-performances');
  const onClickNosPublications = () => navigate('/nos-publications');
  const onClickMentionsLegales = () => navigate('/mentions-legales');

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${process.env.REACT_APP_API_URI}${
            isDesktop
              ? '/uploads/footer_background_logo_9d46a924a0.png)'
              : '/uploads/Footer_Mobile_5_7e2703d942.gif)'
          }`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundPosition: isInViewFooter ? 'top 0px right 0' : 'top 0px right 0',
          transitionDuration: '2s'
        }}
        className="section-footer">
        <div className="section-footer-container">
          <div className="footer-section-1">
            <div className="logo-footer-1">
              <img
                className="fit-picture-footer"
                src={`${process.env.REACT_APP_API_URI}/uploads/logo_blanc_22e33625db.png`}
                alt="logo-blanc"
              />
            </div>
            <div className="footer-adresse-container">
              {isDesktop && <div className="casablanca">Casablanca</div>}
              <div className="footer-adresse-postale">
                3 Bd Al Massira Al Khadra, Casablanca 20330
              </div>
              <div className="footer-adresse-postale">05 22 05 12 12</div>
              <div onClick={onClickMentionsLegales} className="footer-infos-legales">
                Informations légales
              </div>
              <div className="footer-map">
                <iframe
                  src={
                    'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13294.877042026996!2d-7.6342152!3d33.5866377!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda7d3e350a669a7%3A0x903fa24ad5080a0d!2sAFG%20Asset%20Management!5e0!3m2!1sfr!2sfr!4v1681278423379!5m2!1sfr!2sfr'
                  }
                  width="100%"
                  height="100%"
                  style={{ border: 0 }}
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="maps"
                />
              </div>
            </div>
            {isDesktop && (
              <div className="qui-somme-nous">
                <div onClick={onClickNousConnaitre} className="qui-somme-nous-element">
                  Nous connaître
                </div>
                <div onClick={onClickNotreEquipe} className="qui-somme-nous-element">
                  Nos équipes
                </div>
                <div onClick={onClickNotreExpertise} className="qui-somme-nous-element">
                  Notre expertise
                </div>
                <div onClick={onClickNosFonds} className="qui-somme-nous-element">
                  Nos Fonds
                </div>
                <div onClick={onClickNosPublications} className="qui-somme-nous-element">
                  Nos publications
                </div>
                <div onClick={onClickNousContacter} className="qui-somme-nous-element">
                  Nous contacter
                </div>
                <div onClick={onClickVl} className="qui-somme-nous-element">
                  VL & Performances
                </div>
              </div>
            )}
          </div>
          <div className="footer-bottom-text">
            <div>AFG ASSET MANAGEMENT</div>
            <div>© Copyright 2023 AFG. All Rights Reserved.</div>
          </div>
        </div>
      </div>
    </>
  );
}
