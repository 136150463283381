import React, { useEffect, useRef, useState } from 'react';

import { bool, object } from 'prop-types';

import { getHomeDataContent } from '../api/api';
import { GammeOpcvmPage } from '../components/performances/section-4-gamme/GammeOpcvmPage';
import { Section } from '../components/Section';
import { PresentationGenerale } from './section-1-pres-gen-bis/PresentationGenerale';
import { KpisPage } from './section-2-kpis/KpisPage';
import { NosClientsPage } from './section-3-nos-clients/NosClientsPage';
import { Equipe } from './section-5-equipe/Equipe';

import './HomePage.scoped.scss';
import { useIsInViewport } from '../components/afg-monetaire/section-3-first-2-squars/FcpManagementSquars1';

HomePage.propTypes = {
  isDesktop: bool,
  isInViewVideo: bool,
  footerComponent: object,
  scrollToNousConnaitre: bool
};

export function HomePage({ isDesktop, isInViewVideo, footerComponent, scrollToNousConnaitre }) {
  const [contentData, setContentData] = useState(null);
  const [isMuted, setIsMuted] = useState(true);

  const secondSection = useRef();

  const refPresentationGenerale = useRef();
  const isInViewPresentationGeneraleNoMediaQuery = useIsInViewport(refPresentationGenerale);
  const isInViewPresentationGenerale = !isDesktop || isInViewPresentationGeneraleNoMediaQuery;
  const refKpis = useRef();
  const refKpisSection = useRef();
  const isInViewKpisNoMediaQuery = useIsInViewport(refKpis);
  const isInViewKpisSectionNoMediaQuery = useIsInViewport(refKpisSection);
  const isInViewKpis = isDesktop ? isInViewKpisNoMediaQuery : isInViewKpisSectionNoMediaQuery;
  const refGamme = useRef();
  const isInViewGammeNoMediaQuery = useIsInViewport(refGamme);
  const isInViewGamme = !isDesktop || isInViewGammeNoMediaQuery;

  const refNosClients = useRef();
  const isInViewNosClientsNoMediaQuery = useIsInViewport(refNosClients);
  const isInViewNosClients = !isDesktop || isInViewNosClientsNoMediaQuery;

  const refEquipe = useRef();
  const isInViewEquipeNoMediaQuery = useIsInViewport(refEquipe);
  const isInViewEquipe = !isDesktop || isInViewEquipeNoMediaQuery;
  useEffect(() => {
    getHomeDataContent().then((data) => {
      setContentData(data?.data);
      const element = document.getElementById('sectionNousConnaitre');
      if (isDesktop && scrollToNousConnaitre) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }
    });
  }, []);
  document.body.classList.add('hidden-scrollbar-y');
  const onClick = (play) => {
    setIsMuted(play);
  };
  const playerRef = useRef(null);
  const handlePlayerClick = () => {
    const player = playerRef.current.getInternalPlayer();
    player.setVolume(1);
  };

  const onClickNousConnaitre = () => {
    const element = document.getElementById('sectionNousConnaitre');

    element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  };
  return (
    <>
      {/* <ResponsiveAppBar
        isDesktop={isDesktop}
        isSticky={!isDesktop}
        isFirstSectionHome={!isDesktop || isInViewVideo}
        isAuthenticated={isAuthenticated}
        handleLogout={handleLogout}
      /> */}
      <div className="one-page-container">
        <Section id="section0">
          <div className="transition-div-video" />
          <div
            className="video-super-container"
            onClick={() => {
              onClick(!isMuted);
              handlePlayerClick();
            }}>
            <div className="video-container">
              <video
                src={`${process.env.REACT_APP_API_URI}${
                  isDesktop
                    ? '/uploads/AFG_6cbf4bc697.mp4'
                    : '/uploads/Mobile_Version_115dd711a1.mp4'
                }`}
                width="100%"
                autoPlay
                muted={isMuted}
                loop
                playsInline
              />
            </div>
          </div>
          {isDesktop && (
            <div
              style={{
                position: 'absolute',
                opacity: isInViewVideo ? 1 : 0,
                bottom: isInViewVideo ? '100px' : '200px',
                transitionDuration: '1s',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100vw'
              }}
              onClick={onClickNousConnaitre}
              className="scroll-down-img-container">
              <img src={`${process.env.REACT_APP_API_URI}/uploads/scroll_down_079c28370d.svg`} alt="" />
            </div>
          )}
        </Section>
        <div id="sectionNousConnaitre" ref={secondSection} className="sections-container">
          <Section id="section1">
            <div className="transition-div" ref={refPresentationGenerale} />
            {contentData?.presentationGenerale && (
              <PresentationGenerale
                isInViewPresentationGenerale={isInViewPresentationGenerale}
                contentData={contentData?.presentationGenerale}
                isDesktop={isDesktop}
              />
            )}
          </Section>
          <Section ref={refKpisSection} id="section2">
            <div className="transition-div" ref={refKpis} />
            {contentData && (
              <KpisPage
                isDesktop={isDesktop}
                isInViewKpis={isInViewKpis}
                contentData={contentData?.kpis}
              />
            )}
          </Section>
          <Section id="section3">
            <div className="transition-div" ref={refNosClients} />
            <NosClientsPage
              contentData={contentData?.nosClients}
              isInViewNosClients={isInViewNosClients}
              isDesktop={isDesktop}
            />
          </Section>
          <Section id="section4">
            <div className="transition-div" ref={refGamme} />
            <GammeOpcvmPage isDesktop={isDesktop} isInViewGamme={isInViewGamme} />
          </Section>
          <Section id="section5">
            <div className="transition-div" ref={refEquipe} />
            <Equipe
              contentData={contentData?.equipe}
              isInViewEquipe={isInViewEquipe}
              isDesktop={isDesktop}
            />
          </Section>
          {footerComponent}
        </div>
      </div>
    </>
  );
}
