import React from 'react';

import './ProfilGestion.scoped.scss';
import { bool, object } from 'prop-types';
ProfilGestion.propTypes = {
  isDesktop: bool,
  contentData: object
};
export function ProfilGestion({ isDesktop, contentData }) {
  return (
    <>
      <div className="section-5-profil-gestion">
        <div
          style={{
            backgroundImage: `url(${process.env.REACT_APP_API_URI}/uploads/Profil_gestion_c87d784e6f.gif)`,
            backgroundSize: 'cover',
            backgroundPositionX: 'center'
          }}
          className="section-profil-gestion-container-background">
          <div className="section-profil-gestion-container">
            <div className="section-titre-profil-gestion-text">
              {contentData?.profilGestion?.titre}
            </div>
            <div className="strategie-benchmark-container">
              <div className="side-strategie">
                <div className="profil-gestion-titre-divider" />
                <div className="ticket-strategie">{contentData?.profilGestion?.sousTitre}</div>
                <div className="paragraph-under-ticket-strategie">
                  {contentData?.profilGestion?.paragraphe}
                </div>
                {contentData?.profilGestion?.effets?.map((effet, index) => (
                  <div key={index} className="list-opportunite-el">
                    {effet}
                  </div>
                ))}
                {isDesktop && <div className="profil-gestion-titre-divider-2" />}
              </div>
              <div className="side-benchmark">
                <div className="benchmark-titre">
                  {contentData?.profilGestion?.libelleBenchmarque}
                </div>
                <div className="benchmark-divider" />
                <div className="sub-benchmark-titre">
                  {contentData?.profilGestion?.valueBenchmark}
                </div>

                <div className="benchmark-titre">{contentData?.profilGestion?.libelleHorizon}</div>
                <div className="benchmark-divider" />
                <div className="sub-benchmark-titre">
                  {contentData?.profilGestion?.valueHorizon}
                </div>

                <div className="benchmark-titre">{contentData?.profilGestion?.libelleObjectif}</div>
                <div className="benchmark-divider" />
                <div className="sub-benchmark-titre">
                  {contentData?.profilGestion?.valueObjectif}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
