import React from 'react';

import { bool, func, object } from 'prop-types';
import AppsIcon from '@mui/icons-material/Apps';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import './PageProfil.scoped.scss';

PageProfil.propTypes = {
  profil: object,
  handleMoveForward: func,
  handleMoveBackward: func,
  handleClickProfil: func,
  isDesktop: bool
};

export function PageProfil({
  isDesktop,
  profil,
  handleMoveForward,
  handleMoveBackward,
  handleClickProfil
}) {
  function handleClick(url) {
    window.open(url);
  }
  return (
    <>
      <div className="page-profil">
        <div className="page-profil-section-1-container-background">
          <div className="page-profil-section-1-container">
            <div className="notre-equipe-profil-img-container">
              <img className="page-profil-photo" src={profil.pathImg} alt={`${profil?.nom}`}/>
            </div>
            <div className="page-profil-informations">
              <div className="page-profil-titre-1">NOS ÉQUIPES</div>
              <div className="page-profil-nom-text">{profil?.nom}</div>
              <div className="page-profil-titre">{profil?.poste}</div>
              <div className="page-profil-contact">
                <div
                  onClick={() => handleClick(profil?.linkedIn)}
                  className="notre-equipe-profil-img-container">
                  <img
                    className="img-linkedin"
                    src={`${process.env.REACT_APP_API_URI}/uploads/linkedin_icone_b420e5f8f2.png`}
                    alt={`${profil?.nom}`} />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: isDesktop ? 'row' : 'column-reverse',
                    alignItems: 'center'
                  }}>
                  <div className="page-profil-numero">{profil?.numero}</div>
                  <div
                    onClick={() => (window.location = 'mailto:' + profil?.email)}
                    className="page-profil-email">
                    {profil?.email}
                  </div>
                </div>
              </div>
            </div>
            {isDesktop && (
              <div className="page-profil-buttons">
                <div className="container-icone-navigation">
                  <AppsIcon
                    onClick={() => handleClickProfil(0)}
                    sx={{ color: '#FFFFFF', cursor: 'pointer' }}
                  />
                </div>
                <div className="container-icone-navigation">
                  <ArrowForwardIosIcon
                    onClick={handleMoveForward}
                    sx={{ color: '#FFFFFF', cursor: 'pointer' }}
                  />
                </div>
                <div className="container-icone-navigation">
                  <ArrowBackIosNewIcon
                    onClick={handleMoveBackward}
                    sx={{ color: '#FFFFFF', cursor: 'pointer' }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="page-profil-paragraph-container">
          <div className="page-profil-paragraph">
            {profil?.description?.map((description, index) => (
              <div key={index}>
                {description}
                <br />
                <br />
              </div>
            ))}
          </div>
          {/* <div className="page-profil-formation">FORMATION</div>
          {profil?.formation.map(formation => (
            <>
              <div className="page-profil-ecole">{formation.ecole}</div>
              <div className="page-profil-specialte">{formation.specialite}</div>
            </>
          ),
          )} */}
        </div>
        {!isDesktop && (
          <div className="page-profil-buttons-mobile">
            <div className="container-icone-navigation">
              <ArrowBackIosNewIcon
                onClick={handleMoveBackward}
                sx={{ color: '#FFFFFF', cursor: 'pointer', height: '56px', width: '56px' }}
              />
            </div>
            <div className="container-icone-navigation-mobile">
              <AppsIcon
                onClick={() => handleClickProfil(0)}
                sx={{ color: '#FFFFFF', cursor: 'pointer', height: '56px', width: '56px' }}
              />
            </div>
            <div className="container-icone-navigation">
              <ArrowForwardIosIcon
                onClick={handleMoveForward}
                sx={{ color: '#FFFFFF', cursor: 'pointer', height: '56px', width: '56px' }}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
