import React, { useEffect, useRef, useState } from 'react';

import { bool } from 'prop-types';

import { StackedCards } from './StackedCards';

import './GammeOpcvmPage.scoped.scss';
import { getGammeOPCVMContent } from '../../../api/api';

GammeOpcvmPage.propTypes = {
  isDesktop: bool,
  isInViewGamme: bool,
  isNosFonds: bool
};

export function GammeOpcvmPage({ isDesktop, isInViewGamme, isNosFonds }) {
  const [contentData, setContentData] = useState(null);
  const [offreId, setOffreId] = useState(0);
  const setOffreIdAndAnimate = (id) => {
    setOffreId(id);
  };

  const refCards = useRef();

  const [isDragging, setIsDragging] = useState(false);
  const [dragStartX, setDragStartX] = useState(0);
  const [dragOffsetX, setDragOffsetX] = useState(0);
  const [previousDragOffsetX, setPreviousDragOffsetX] = useState(0);
  const [scrollLevel, setScrollLevel] = useState(0);
  const handleMouseDown = (event) => {
    setIsDragging(true);
    setDragStartX(event.clientX);
  };

  const handleMouseMove = (event) => {
    if (isDragging) {
      const offset = previousDragOffsetX + event.clientX - dragStartX;
      setDragOffsetX(offset);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setPreviousDragOffsetX(dragOffsetX);
  };
  useEffect(() => {
    getGammeOPCVMContent().then((data) => setContentData(data?.data));
    function handleScroll() {
      setScrollLevel(window.scrollY);
    }

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  let opacityCalculated = 1;
  if (isNosFonds) {
    if (refCards.current?.getBoundingClientRect().left < 680) {
      opacityCalculated = 0;
    }
  }

  return (
    <>
      <div onMouseMove={handleMouseMove} onMouseUp={handleMouseUp} className="section-gamme">
        <div className="section-gamme-container">
          <div
            style={{
              transform: isInViewGamme ? 'translate(0vw, 0vh)' : 'translate(-15vw, 0vh)',
              transitionDuration: '1s',
              opacity: opacityCalculated
            }}
            className="section-gamme-side-left">
            <div className="section-gamme-side-left-container">
              <div className="title-gamme">{contentData?.title}</div>
              <div className="side-left-paragraph">
                {contentData?.paragraphe1}
                <br />
                <br />
                {contentData?.paragraph2}
              </div>
              {isDesktop &&
                !isNosFonds &&
                contentData?.listeOffres
                ?.filter((offre) => {
                  const idToExclude = (offreId === 3 ? 2 : 3);
                  return offre.id !== idToExclude && offre.id !== 4;
              })
                  .map((offre) => (
                    <div
                      key={offre.id}
                      onClick={() => setOffreIdAndAnimate(offre.id)}
                      className={
                        offre.id === offreId
                          ? 'offres-list-element-container-activated'
                          : 'offres-list-element-container'
                      }
                      style={{
                        backgroundColor: offre.id === offreId && offre.backgroundColor,
                        color: offre.id === offreId && offre.textColor
                      }}>
                      <span
                        className={
                          offre.id === offreId
                            ? 'offres-list-element-text-activated'
                            : 'offres-list-element-text'
                        }>
                        {offre.label}
                      </span>
                    </div>
                  ))}
            </div>
          </div>
          <div className="section-gamme-side-right">
            <div className="section-gamme-side-right-container">
              {contentData?.listeOffres && (
                <StackedCards
                  refCards={refCards}
                  isInViewGamme={isInViewGamme}
                  listOffres={contentData?.listeOffres}
                  idOffreActivated={offreId}
                  setOffreId={setOffreIdAndAnimate}
                  isNosFonds={isNosFonds}
                  handleMouseDown={handleMouseDown}
                  handleMouseUp={handleMouseUp}
                  isDragging={isDragging}
                  dragOffsetX={
                    scrollLevel > 400 && isDesktop && isNosFonds
                      ? dragOffsetX - scrollLevel + 200
                      : dragOffsetX
                  }
                  isDesktop={isDesktop}
                />
              )}
              <div
                style={{ position: 'relative', marginTop: '50px' }}
                className="div-start-scroll"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
