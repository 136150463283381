import React, { useRef } from 'react';

import { bool, object } from 'prop-types';

import './NosClientsPage.scoped.scss';
import { Card } from './Card';

NosClientsPage.propTypes = {
  isInViewNosClients: bool,
  isDesktop: bool,
  contentData: object
};

export function NosClientsPage({ isInViewNosClients, isDesktop, contentData }) {
  const ref = useRef();
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${process.env.REACT_APP_API_URI}/uploads/nos_clients_background_9ca2926556.jpg)`,
          backgroundColor: '#cccccc',
          backgroundPositionX: isInViewNosClients ? '0%' : '-10%',
          transitionDuration: '1s'
        }}
        className="section-nos-clients">
        <div className="section-nos-clients-container">
          <div ref={ref} className="three-cards-container">
            <div>
              <div
                style={{
                  transform:
                    !isDesktop || isInViewNosClients
                      ? 'translate(0vw, 0vh)'
                      : 'translate(-10vw, 0vh)',
                  opacity: !isDesktop || isInViewNosClients ? '100%' : '0%',
                  transitionDuration: '1s'
                }}>
                <div className="nos-clients-title">{contentData?.title}</div>
              </div>
              <div className="three-cards">
                <Card
                  iconeUrl={`${process.env.REACT_APP_API_URI}/uploads/les_investisseurs_8ec005b853.png`}
                  paragraph={contentData?.paragraph1}
                  title={contentData?.title1}
                  isInViewNosClients={isInViewNosClients}
                />
                <Card
                  iconeUrl={`${process.env.REACT_APP_API_URI}/uploads/les_entreprises_937574ae9e.png`}
                  paragraph={contentData?.paragraph2}
                  title={contentData?.title2}
                  isInViewNosClients={isInViewNosClients}
                />
                <Card
                  iconeUrl={`${process.env.REACT_APP_API_URI}/uploads/les_personnes_physiques_dba7e12cee.png`}
                  paragraph={contentData?.paragraph3}
                  title={contentData?.title3}
                  isInViewNosClients={isInViewNosClients}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
