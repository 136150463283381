import * as React from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Menu } from '@mui/material';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import { bool, func, object } from 'prop-types';

import './MenusPerf.scoped.scss';
MenusPerf.propTypes = {
  isDesktop: bool,
  setPerformanceKey: func,
  performanceKey: object
};

const performanceKeys = [
  { key: 'ytd', label: 'YTD' },
  { key: 'unJour', label: '1 jour' },
  { key: 'uneSemaine', label: '1 semaine' },
  { key: 'unMois', label: '1 mois' },
  { key: 'troisMois', label: '3 mois' },
  { key: 'sixMois', label: '6 mois' },
  { key: 'unAn', label: '1 an' },
  { key: 'deuxAns', label: '2 ans' }
];

export function MenusPerf({ setPerformanceKey, performanceKey }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const pickType = (typeEl) => {
    setPerformanceKey(typeEl);
    handleClose();
  };

  return (
    <div>
      <div className="button-type-content">
        <button
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          type="button"
          className="vl-button-type">
          <div className="button-type-label">{performanceKey.label}</div>
          <div className="icon-menu">
            <KeyboardArrowDownIcon fontSize="large" />
          </div>
        </button>
      </div>

      <Menu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: '56.71875vw',
            maxWidth: '350px'
          }
        }}>
        {performanceKeys?.map((perfkey, index) => [
          <MenuItem
            key={index}
            sx={{
              fontSize: '20px'
            }}
            onClick={() => pickType(perfkey)}
            disableRipple>
            {perfkey.label}
          </MenuItem>,
          index !== 7 && <Divider key={`divider-${index}`} sx={{ my: 0.5 }} />
        ])}
      </Menu>
    </div>
  );
}
