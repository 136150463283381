/* eslint-disable react/display-name */
// Section.jsx

import React from 'react';

import { array, string } from 'prop-types';

export const Section = React.forwardRef(({ id, children }, ref) => (
  <section ref={ref} id={id}>
    {children}
  </section>
));

Section.propTypes = {
  id: string,
  children: array
};
