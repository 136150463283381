import React, { useEffect, useRef, useState } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import { getExcelGraphData } from '../../../api/api';
import { fiche } from '../../performances/section-4-gamme/StackedCards';

import { SlideFadeText } from './SlideFadeText';

import './GammesPerformances.scoped.scss';
import { getGammePerformancesContent } from '../../../api/api';
import { bool } from 'prop-types';
import { Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';

GammesPerformances.propTypes = {
  isDesktop: bool
};

export function GammesPerformances({ isDesktop }) {
  const [contentData, setContentData] = useState(null);

  const parentRef = useRef();

  const [idGamme, setIdGamme] = useState(2);
  const [direction, setDirection] = useState('unset');
  const [inProp, setInProp] = useState(true);

  const handleClickBack = () => {
    setDirection('left');
    setInProp(false);
    if (idGamme === 1) {
      setIdGamme(6);
    } else {
      setIdGamme(idGamme - 1);
    }
  };


  const [hasDataBeenFetched, setHasDataBeenFetched] = useState(false);
  const [excelData, setExcelData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const sheetLabel = fiche.find((prod) => prod?.id === Number(idGamme)).sheetName;
      const data = await getExcelGraphData(sheetLabel);
      setExcelData(data);
    };
  
    fetchData();
  }, [idGamme]);

  // useEffect(() => {
  //   if (!hasDataBeenFetched) {
  //     setHasDataBeenFetched(true);
  //     getExcelGraphData(sheetLabel).then((data) => {
  //       setExcelData(data);
  //       setHasDataBeenFetched(true);
  //     });
  //   }
  // }, [idGamme, excelData]);

  // let lastDataRow   = "";
  const [lastAN, setLastAN] = useState("");

useEffect(() => {
  if (excelData) {
    const lastRow = excelData[excelData.length - 1];
    const value = lastRow[lastRow.length - 10];
    setLastAN(value);
  }
}, [excelData]);

  useEffect(() => {
    getGammePerformancesContent().then((data) => setContentData(data));
  }, []);

  const positionAbsoluteArrow =
    document.getElementById('carreau' + idGamme)?.getBoundingClientRect().left -
    parentRef.current?.getBoundingClientRect().left;

  const fontSizeTextPosition = isDesktop
    ? 'normal normal 500 calc(4.5px + 0.695vw)/22px Aileron-Regular'
    : 'normal normal 500 10px/16px Aileron-Regular';

  const handleClickForward = () => {
    setDirection('right');
    setInProp(false);
    if (idGamme === 6) {
      setIdGamme(1);
    } else {
      setIdGamme(idGamme + 1);
    }
  };

  const listGammes = contentData?.data?.gammes ? contentData?.data?.gammes : [];

  const listPeriodes = [
    { libelle: '< à 6 mois', color: '#A9ABB6' },
    { libelle: '[6 mois-1 an]', color: '#9396A2' },
    { libelle: '> 1 an', color: '#7D8191' },
    { libelle: '> 1 an', color: '#7D8191' },
    { libelle: '> 2 an', color: '#52576D' },
    { libelle: '> 3 an', color: '#282D48' }
  ]; 

  const navigate = useNavigate();
  const onClickAfgMonetaire = () => navigate('/notre-gamme/' + idGamme);

  return (
    <>
      <div className="section-performances">
        <div ref={parentRef} className="section-performances-container">
          <div className="section-performances-title">
            <div className="section-performances-logo-back">
              {idGamme >= 1 && <ArrowBackIcon onClick={handleClickBack} />}
            </div>
            <SlideFadeText
              text={listGammes.find((gamme) => gamme.id === idGamme)?.titre}
              direction={direction}
              setDirection={setDirection}
              inProp={inProp}
              setInProp={setInProp}
            />

            <div className="section-performances-logo-front">
              {idGamme <= 6 && <ArrowForwardIcon onClick={handleClickForward} />}
            </div>
          </div>
          <div onClick={onClickAfgMonetaire} className="section-performances-sub-title-1">
            <SlideFadeText
              text={listGammes.find((gamme) => gamme.id === idGamme)?.sousTitre1}
              direction={direction}
              setDirection={setDirection}
              inProp={inProp}
              setInProp={setInProp}
              fade
              idProduit={idGamme}
            />
          </div>
          <div className="section-performances-sub-title-2">
            <SlideFadeText
              text={listGammes.find((gamme) => gamme.id === idGamme)?.sousTitre2}
              direction={direction}
              setDirection={setDirection}
              inProp={inProp}
              setInProp={setInProp}
              sousTitre2 = {true}
              idProduit={idGamme}
              lastAN = {lastAN}
            />
          </div>
          <div className="section-performances-titre-durees">Horizon de placement recommandé</div>
          <div className="section-performances-logo-durees-container">
            <div className="section-performances-logo-durees-container-bis">
              
              {
              listPeriodes.map((periode, index) => (
                <div key={index} className="position-text-container">
                  <LocationOnIcon
                    style={{
                      fontSize: idGamme === index + 1 ? '45px' : '50px',
                      color: idGamme === index + 1 ? '#C1A367' : periode.color,
                      cursor: 'pointer'
                    }}
                    onClick={() => setIdGamme(index + 1)}
                  />
                  <div
                    style={{
                      textAlign: 'center',
                      font: fontSizeTextPosition,
                      color: idGamme === index + 1 ? '#C1A367' : periode.color,
                      whiteSpace: 'nowrap'
                    }}>
                    <Tooltip title={periode.libelle}>
                      <span className="text-overflow-center">{periode.libelle}</span>
                    </Tooltip>
                  </div>
                </div>
              ))}
            </div>
            <div className="section-performances-logo-durees-container-bis"></div>
          </div>
          <div className="section-performances-titre-couleurs">Profil de risque</div>
          <div
            style={{
              transitionDuration: '1s',
              alignSelf: 'flex-start',
              color: '#C1A367',
              height: '30px'
            }}>
            <div
              style={{
                top: '15px',
                transform: `translate(${positionAbsoluteArrow + (isDesktop ? 15 : 10) + 'px'}, 0)`,
                transitionDuration: '1s'
              }}>
              <ArrowDownwardIcon />
            </div>
          </div>
          <div className="section-performances-logo-durees-container">
            <div className="section-performances-logo-durees-container-bis">
              <div className="section-performances-logo-durees">
                <div
                  id="carreau1"
                  style={{
                    height: isDesktop ? '45px' : '50px',
                    width: isDesktop ? '45px' : '50px',
                    background: idGamme === 1 ? '#C1A367' : '#A9ABB6',
                    borderRadius: '10px',
                    cursor: 'pointer'
                  }}
                  onClick={() => setIdGamme(1)}
                />
              </div>
              <div className="section-performances-logo-durees">
                <div
                  id="carreau2"
                  style={{
                    height: isDesktop ? '45px' : '50px',
                    width: isDesktop ? '45px' : '50px',
                    background: idGamme === 2 ? '#C1A367' : '#9396A2',
                    borderRadius: '10px',
                    cursor: 'pointer'
                  }}
                  onClick={() => setIdGamme(2)}
                />
              </div>
              <div className="section-performances-logo-durees">
                <div
                  id="carreau3"
                  style={{
                    height: isDesktop ? '45px' : '50px',
                    width: isDesktop ? '45px' : '50px',
                    background: idGamme === 3 ? '#C1A367' : '#7D8191',
                    borderRadius: '10px',
                    cursor: 'pointer'
                  }}
                  onClick={() => setIdGamme(3)}
                />
              </div>
              <div className="section-performances-logo-durees">
                <div
                  id="carreau4"
                  style={{
                    height: isDesktop ? '45px' : '50px',
                    width: isDesktop ? '45px' : '50px',
                    background: idGamme === 4 ? '#C1A367' : '#7D8191',
                    borderRadius: '10px',
                    cursor: 'pointer'
                  }}
                  onClick={() => setIdGamme(4)}
                />
              </div>
              <div className="section-performances-logo-durees">
                <div
                  id="carreau5"
                  style={{
                    height: isDesktop ? '45px' : '50px',
                    width: isDesktop ? '45px' : '50px',
                    background: idGamme === 5 ? '#C1A367' : '#52576d',
                    borderRadius: '10px',
                    cursor: 'pointer'
                  }}
                  onClick={() => setIdGamme(5)}
                />
              </div>
              <div className="section-performances-logo-durees">
                <div
                  id="carreau6"
                  style={{
                    height: isDesktop ? '45px' : '50px',
                    width: isDesktop ? '45px' : '50px',
                    background: idGamme === 6 ? '#C1A367' : '#282D48',
                    borderRadius: '10px',
                    cursor: 'pointer'
                  }}
                  onClick={() => setIdGamme(6)}
                />
              </div>
              
              
            </div>
          </div>
          <div className="legende-risque-container">
            <div className="legende-risque-container-text-left">Risque faible</div>
            <div className="legende-risque-container-text-right">Risque élevé</div>
          </div>
          <div className="section-performances-titre-couleurs-mot">Moteurs de performance clés</div>

          <div className="section-performances-moteur-performances">
            <div className="moteur-performance-element-container">
              {listGammes
                .find((gamme) => gamme.id === idGamme)
                ?.moteursCles.map((moteurCle, indexM) => (
                  <div key={indexM} className="moteur-performance-element">
                    <div className="moteur-performance-element-key">{moteurCle.name}</div>
                    <div className="moteur-performance-element-values-container">
                      <div className="moteur-performance-element-values">
                        {[...Array(moteurCle.value)].map((el, index) => (
                          <div key={index + 10} className="performance-trait-rempli" />
                        ))}
                        {[...Array(4 - Number(moteurCle.value))].map((el, index) => (
                          <div key={4 - index} className="performance-trait-vide" />
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="section-performances-titre-remarque-container">
            <div className="section-performances-titre-remarque">
              * Degré d’activation du levier de performance, le maximum étant
            </div>
            <div className="section-performances-titre-remarque-values">
              {[...Array(4)].map((el, index) => (
                <div key={index} className="performance-trait-rempli-remarque" />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
