import { object } from 'prop-types';
import React from 'react';

import './InformationsLegales.scoped.scss';
InformationsLegales.propTypes = {
  contentData: object
};

export function InformationsLegales({ contentData }) {
  return (
    <>
      <div className="section-5-informations-legales">
        <div className="section-informations-legales-container-background">
          <div className="section-informations-legales-container">
            <div className="section-titre-informations-legales">
              {contentData?.infosLegales.titre}
            </div>
            <div className="informations-legales-titre-divider" />
            <div className="section-informations-legales-text">
              {contentData?.infosLegales.paragraphe}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
