import React, { useEffect, useRef, useState } from 'react';

import { Section } from '../Section';
import { SectionBis, SectionBis2, SectionBis5 } from '../SectionBis';
import { AfgMonetaireTitre } from './section-1-titres/AfgMonetaireTitre';
import { FcpManagementTitre, useIsInViewport } from './section-2-FcpManagement/FcpManagement';
import { FcpManagementSquars1 } from './section-3-first-2-squars/FcpManagementSquars1';
import { CaracteristiqueGestion } from './section-4-caracteristiques-gestion/CaracteristiqueGestion';
import { ProfilGestion } from './section-5-profil-gestion/ProfilGestion';
import { PerformancesAfg } from './section-6-performances/PerformancesAfg';
import { EvolutionPerformance } from './section-7-evolution-performance/EvolutionPerformance';
import { Documentation } from './section-8-documentation/Documentation';
import { InformationsLegales } from './section-9-informations-legales/InformationsLegales';

import './AfgMonetaire.scoped.scss';
import { bool, object } from 'prop-types';
import { useParams } from 'react-router-dom';
import { getAfgContent } from '../../api/api';

AfgMonetaire.propTypes = {
  isDesktop: bool,
  footerComponent: object
};

export function AfgMonetaire({ isDesktop, footerComponent }) {
  const { id } = useParams();
  const [hasDataBeenFetched, setHasDataBeenFetched] = useState(false);
  const [contentData, setContentData] = useState(null);
  const [expandLevels, setExpandLevels] = useState(false);
  const [transitionSquars, setTransitionSquars] = useState(false);
  const [transition2, setTransition2] = useState(false);
  const [grey, setGrey] = useState(false);
  const refTitlePrincipal = useRef(null);
  const refSquars1 = useRef(null);
  const refSquars2 = useRef(null);
  const [showAnimationDepart, setShowAnimationDepart] = useState(true);

  const containerRef = useRef(null);

  const isInViewPortTitlePrincipal = useIsInViewport(refTitlePrincipal) || !isDesktop;

  const isInViewPortSquars1 = useIsInViewport(refSquars1) || !isDesktop;
  const isInViewPortSquars2 = useIsInViewport(refSquars2) || !isDesktop;
  useEffect(() => {
    if (!hasDataBeenFetched) {
      setHasDataBeenFetched(true);
      getAfgContent(id).then((data) => {
        setContentData(data?.data);
        setHasDataBeenFetched(true);
      });
      setTimeout(() => setShowAnimationDepart(false), 1500);
    }
  }, [contentData]);
  useEffect(() => {
    const element = document.getElementById('section-2');
    if (isDesktop) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  }, [id]);

  useEffect(() => {
    document.body.classList.add('hidden-scrollbar-y');
    if (isInViewPortSquars1) {
      setTimeout(() => setExpandLevels(true), 500);
      setTimeout(() => setGrey(true), 1500);
      setTransitionSquars(true);
    }
    if (isInViewPortTitlePrincipal) {
      setExpandLevels(false);
      setGrey(false);
      setTransitionSquars(false);
    }
    setTransition2(isInViewPortSquars2);
    return () => {
      document.body.classList.remove('hidden-scrollbar-y');
    };
  }, [isInViewPortSquars1, isInViewPortSquars2, isInViewPortTitlePrincipal]);

  if (!isInViewPortSquars1 && (grey === true || expandLevels === true)) {
    setGrey(false);
    setExpandLevels(false);
  }

  return (
    <>
      <div ref={containerRef} className="performances-page-afg-monetaire">
        <SectionBis id="section-1">
          <div className="transition-div-bis" ref={refTitlePrincipal} />
          <AfgMonetaireTitre idPage={id} />
        </SectionBis>

        {isDesktop ? (
          <SectionBis2 id="section-2">
            <FcpManagementTitre
              isDesktop={isDesktop}
              idPage={id}
              contentData={contentData}
              isInViewPortTitlePrincipal={isInViewPortTitlePrincipal}
            />
          </SectionBis2>
        ) : (
          <div id="section-2">
            <FcpManagementTitre
              isDesktop={isDesktop}
              idPage={id}
              contentData={contentData}
              isInViewPortTitlePrincipal={isInViewPortTitlePrincipal}
            />
          </div>
        )}
        {isDesktop && (
          <div
            style={{
              height: '100vh',
              transitionDuration: '2s',
              transform: showAnimationDepart
                ? 'translate(0, -114.72vh)'
                : 'translate(0, -214.722vh)',
              width: '100vw',
              position: 'absolute',
              backgroundImage: `url(${process.env.REACT_APP_API_URI}/${
                isDesktop
                  ? 'uploads/animation_depart_007eced996.gif'
                  : '/uploads/animation_logo_mobile_6e5127ca16.gif'
              })`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              zIndex: 1002
            }}
          />
        )}
        <Section id="section-3">
          <div className="transition-div-bis" ref={refSquars1} />
          <FcpManagementSquars1
            contentData={contentData}
            isDesktop={isDesktop}
            grey={grey}
            transitionSquars={transitionSquars}
            expandLevels={expandLevels}
            transition2={transition2}
            refSquars2={refSquars2}
            idProduit={id}
          />
        </Section>
        <SectionBis5 id="section-4">
          <CaracteristiqueGestion contentData={contentData} isDesktop={isDesktop} />
        </SectionBis5>
        <Section id="section-5">
          <ProfilGestion contentData={contentData} isDesktop={isDesktop} />
        </Section>
        <SectionBis5 id="section-7">
          <PerformancesAfg 
            contentData={contentData}
            isDesktop={isDesktop}
            grey={grey}
            transitionSquars={transitionSquars}
            expandLevels={expandLevels}
            transition2={transition2}
            refSquars2={refSquars2}
            idProduit={id} />
        </SectionBis5>
        <Section id="section-8">
          <EvolutionPerformance contentData={contentData} isDesktop={isDesktop} idProduit={id} />
        </Section>
        <SectionBis id="section-9">
          <Documentation idx={Number(id)} contentData={contentData} />
        </SectionBis>
        <Section id="section-10">
          <InformationsLegales contentData={contentData} />
        </Section>
        {footerComponent}
      </div>
    </>
  );
}
