import React, { useEffect, useRef, useState } from 'react';

import { bool, object } from 'prop-types';

import { Section } from '../Section';
import { Presentation } from './section-1-presentation/Presentation';
import { Graph } from './section-2-graph/Graph';
import { GammesPerformances } from './section-3-performances/GammesPerformances';
import { GammeOpcvmPage } from './section-4-gamme/GammeOpcvmPage';

import './Performances.scoped.scss';
import { getExpertiseContent } from '../../api/api';
import { useIsInViewport } from '../afg-monetaire/section-2-FcpManagement/FcpManagement';
import { TextSection } from './section-2-1-text/section-2-graph/Text';

Performances.propTypes = {
  isDesktop: bool,
  footerComponent: object
};

export function Performances({ isDesktop, footerComponent }) {
  const [contentData, setContentData] = useState(null);

  useEffect(() => {
    getExpertiseContent().then((data) => setContentData(data));
    document.body.classList.add('hidden-scrollbar-y');
    return () => {
      document.body.classList.remove('hidden-scrollbar-y');
    };
  }, []);
  const refGamme = useRef();
  let isInViewGammeNoMediaQuery = true;
  isInViewGammeNoMediaQuery = useIsInViewport(refGamme);

  const isInViewGamme = !isDesktop || isInViewGammeNoMediaQuery;
  return (
    <>
      <div>
        <div className="performances-page">
          <Section id="section-1">
            {contentData?.data && <Presentation contentData={contentData?.data} />}
          </Section>
          <Section id="section-4">
            <TextSection contentData={contentData?.data} isDesktop={isDesktop} />
          </Section>
          <Section id="section-2">
            <Graph isDesktop={isDesktop} />
          </Section>
          <Section id="section-4">
            <GammesPerformances isDesktop={isDesktop} />
          </Section>
          {isDesktop ? (
            <Section id="section-3">
              <div className="transition-div" ref={refGamme} />
              <GammeOpcvmPage isDesktop={isDesktop} isInViewGamme={isInViewGamme} />
            </Section>
          ) : (
            <div ref={refGamme} />
          )}
          {footerComponent}
        </div>
      </div>
    </>
  );
}
