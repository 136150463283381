import { string } from 'prop-types';
import React, { useRef } from 'react';
import { fiche } from '../../performances/section-4-gamme/StackedCards';

import './AfgMonetaireTitre.scoped.scss';
AfgMonetaireTitre.propTypes = {
  idPage: string
};

export function AfgMonetaireTitre({ idPage }) {
  const ref = useRef(null);

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${process.env.REACT_APP_API_URI}/uploads/Section_1_titre_593300b25b.gif)`,
          backgroundSize: 'cover',
          backgroundPositionX: 'center'
        }}
        className="section-titre-1">
        <div className="div-lottie" ref={ref} />
        <div className="section-titre-1-text">
          Notre Gamme
          <br />
          {fiche?.find((el) => el.id === Number(idPage))?.titre}
        </div>
      </div>
    </>
  );
}
