import React from 'react';

import FileDownloadIcon from '@mui/icons-material/FileDownload';

import './Documentation.scoped.scss';
import { number, object } from 'prop-types';
import { fiche } from '../../performances/section-4-gamme/StackedCards';

Documentation.propTypes = {
  contentData: object,
  idx: number
};

export function Documentation({ contentData, idx }) {
  const onClickDownloadPdf = (Filename) => {
    fetch(Filename, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf'
      }
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', Filename.slice(48));

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };
  return (
    <>
      <div className="section-8-documentation">
        <div className="section-documentation-container-background">
          <div className="section-documentation-container">
            <div className="section-titre-documentation">{contentData?.documentation?.titre}</div>
            <div className="documentation-titre-divider" />
            <div className="documentation-sides-container">
              <div className="documentation-side-1">
                <div className="documentation-side-titre">
                  {contentData?.documentation?.sousTitre2}
                </div>
                <div className="documentation-side-divider" />
                <div
                  onClick={() => onClickDownloadPdf(fiche?.find((el) => el.id === idx).urlNote)}
                  className="documentation-side-telecharger-container">
                  <div className="documentation-telecharger-icon">
                    <FileDownloadIcon />
                  </div>
                  <div className="documentation-telecharger-text">
                    {contentData?.documentation?.libelleTelecharger}
                  </div>
                </div>
              </div>
              <div className="documentation-side-2">
                <div className="documentation-side-titre">
                  {contentData?.documentation?.sousTitre1}
                </div>
                <div className="documentation-side-divider" />
                <div
                  onClick={() => onClickDownloadPdf(fiche?.find((el) => el.id === idx).urlFiche)}
                  className="documentation-side-telecharger-container">
                  <div className="documentation-telecharger-icon">
                    <FileDownloadIcon />
                  </div>
                  <div className="documentation-telecharger-text">
                    {contentData?.documentation?.libelleTelecharger}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
