import { func } from 'prop-types';
import React, { useEffect, useRef } from 'react';

InactivityLogout.propTypes = {
  handleLogout: func
};
export function InactivityLogout({ handleLogout }) {
  const inactivityTimer = useRef(null);

  useEffect(() => {
    const startInactivityTimer = () => {
      inactivityTimer.current = setTimeout(logoutUser, 2 * 60 * 1000); // 2 minutes in milliseconds
    };

    const resetInactivityTimer = () => {
      clearTimeout(inactivityTimer.current);
      startInactivityTimer();
    };

    const logoutUser = () => {
      handleLogout();
    };

    const handleActivity = () => {
      resetInactivityTimer();
    };

    // Start the inactivity timer on component mount
    startInactivityTimer();

    // Add event listeners to monitor user activity
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keypress', handleActivity);

    // Cleanup: remove event listeners and clear timer on component unmount
    return () => {
      clearTimeout(inactivityTimer.current);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keypress', handleActivity);
    };
  }, []);

  return <></>; // You can return any JSX or component you desire
}
