import React from 'react';

import { bool, object } from 'prop-types';
import { useNavigate } from 'react-router-dom';

import './Equipe.scoped.scss';

Equipe.propTypes = {
  isDesktop: bool,
  isInViewEquipe: bool,
  contentData: object
};

export function Equipe({ isDesktop, isInViewEquipe, contentData }) {
  const navigate = useNavigate();
  const redirectToEquipe = () => navigate('/notre-equipe');
  const handleClickProfil = (id) => {
    navigate(`/notre-equipe/${id}`);
  };
  return (
    <>
      <div className="section-equipe">
        <div className="section-equipe-container">
          <div className="equipe-title">
            <div
              style={{
                transform: isInViewEquipe ? 'translate(0vw, 0vh)' : 'translate(0vw, -20vh)',
                transitionDuration: '1s'
              }}
              className="equipe-title-left">
              <div className="nowrap">{contentData?.titleL1}&nbsp;</div>
              <div>{contentData?.titleL2}</div>
            </div>
          </div>
          <div
            style={{
              transform: isInViewEquipe ? 'translate(0vw, 0vh)' : 'translate(0vw, -20vh)',
              transitionDuration: '1s'
            }}
            className="divider-equipe-expert"
          />
          <div>
            <div className="equipe-profiles">
              <div
                style={{
                  transform: isInViewEquipe ? 'translate(0vw, 0vh)' : 'translate(0, 20vh)',
                  transitionDuration: '1s'
                }}
                key={1}
                onClick={() => handleClickProfil(1)}
                className="equipe-profil">
                <div className="equipe-profil-img-container">
                  <img
                    className="equipe-profil-img"
                    src={`${process.env.REACT_APP_API_URI}/uploads/souhail_46c0a1d296.jpg`}
                    alt={`${contentData?.name1}`}
                  />
                </div>
                <div className="profil-name">{contentData?.name1}</div>
                <div className="profil-poste">{contentData?.fonction1}</div>
              </div>
              <div
                style={{
                  transform: isInViewEquipe ? 'translate(0vw, 0vh)' : 'translate(0, 20vh)',
                  transitionDuration: '1.5s'
                }}
                key={2}
                onClick={() => handleClickProfil(2)}
                className="equipe-profil">
                <div className="equipe-profil-img-container">
                  <img
                    className="equipe-profil-img"
                    src={`${process.env.REACT_APP_API_URI}/uploads/kenza_2b07cc45a5.jpg`}
                    alt={`${contentData?.name2}`}
                  />
                </div>
                <div className="profil-name">{contentData?.name2}</div>
                <div className="profil-poste">{contentData?.fonction2}</div>
              </div>
              <div
                style={{
                  transform: isInViewEquipe ? 'translate(0vw, 0vh)' : 'translate(0, 20vh)',
                  transitionDuration: '2s'
                }}
                key={3}
                onClick={() => handleClickProfil(3)}
                className="equipe-profil">
                <div className="equipe-profil-img-container">
                  <img
                    className="equipe-profil-img"
                    src={`${process.env.REACT_APP_API_URI}/uploads/ghassane_5ac699ba69.jpg`}
                    alt={`${contentData?.name3}`}
                  />
                </div>
                <div className="profil-name">{contentData?.name3}</div>
                <div className="profil-poste">{contentData?.fonction3}</div>
              </div>
              {!isDesktop && (
                <div className="equipe-profil">
                  <div onClick={redirectToEquipe} className="link-tout-equipe-mobile">
                    {contentData?.touteEquipeTxt}
                  </div>
                </div>
              )}
            </div>
            {isDesktop && (
              <div
                style={{
                  opacity: isInViewEquipe ? '1' : '0',
                  transitionDuration: '1s',
                  transitionDelay: '1.5s'
                }}
                onClick={redirectToEquipe}
                className="link-tout-equipe">
                TOUTE L’ÉQUIPE
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
