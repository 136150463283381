import React, { useEffect, useMemo, useState, useRef } from 'react';

import { bool, object, string } from 'prop-types';
import './PerformancesAfg.scoped.scss';

import { getExcelGraphData } from '../../../api/api';
import { fiche } from '../../performances/section-4-gamme/StackedCards';


export function useIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () => new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting)),
    []
  );

  useEffect(() => {
    if (ref.current instanceof Element) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}

PerformancesAfg.propTypes = {
  expandLevels: bool,
  grey: bool,
  transition2: bool,
  refSquars2: object,
  transitionSquars: bool,
  isDesktop: bool,
  contentData: object,
  idProduit: string
};

export function PerformancesAfg({
  contentData,
  idProduit
}) {

  const [hasDataBeenFetched, setHasDataBeenFetched] = useState(false);
  const [excelData, setExcelData] = useState(null);

  const sheetLabel = fiche.find((prod) => prod?.id === Number(idProduit)).sheetName;

  useEffect(() => {
    if (!hasDataBeenFetched) {
      setHasDataBeenFetched(true);
      getExcelGraphData(sheetLabel).then((data) => {
        setExcelData(data);
        setHasDataBeenFetched(true);
      });
    }
  }, [idProduit, excelData]);

  let lastDataRow   = "";
  let FirstDay      = "";
  let FirstWeek     = "";
  let FirstMonth    = "";
  let lastYTD       = "";
  let TreeMonth     = ""; 
  let SixMonth      = ""; 
  let OneYear       = ""; 
  let TwoYear       = ""; 

  let lastDate      = null;

  if (excelData) {
    lastDataRow = excelData[excelData.length - 1];
    lastYTD     = lastDataRow[lastDataRow.length - 8]; 
    FirstDay    = lastDataRow[lastDataRow.length - 7]; 
    FirstWeek   = lastDataRow[lastDataRow.length - 6]; 
    FirstMonth  = lastDataRow[lastDataRow.length - 5]; 
    TreeMonth   = lastDataRow[lastDataRow.length - 4]; 
    SixMonth    = lastDataRow[lastDataRow.length - 3]; 
    OneYear     = lastDataRow[lastDataRow.length - 2]; 
    TwoYear     = lastDataRow[lastDataRow.length - 1]; 
    lastDate    = lastDataRow[lastDataRow.length - 15];
    lastDate    = new Date((lastDate - 25569) * 86400 * 1000);
    lastDate    = lastDate.toLocaleDateString('fr-FR');
  }

  return (
    <>
    <div className="section-5-performances-afg">
      <div className="section-performances-afg-container-background">
        <div className="section-performances-afg-container">
          <div className="section-titre-performances-afg">
            {/* {contentData?.performances?.titrePrincipal} */}
            Performances au {lastDate}
          </div>
          <div className="performances-afg-titre-divider" />
          <div className="performances-afg-sous-titre">
            {contentData?.performances?.sousTitre}
          </div>
          <div className="table-vue-globale">
            <div className="titres-group">
              <div className="row-text-empty" />
              <div className="titre-row-empty" />
              <div className="titre-row-fonds">{contentData?.performances?.tableaux[2][0]}</div>
            </div>
            <div className="performances-brut-group">
              <div className="row-text-brute">{contentData?.performances?.tableaux[0][0]}</div>
              <div className="head-grey-container-1">
                <div className="head-el-grey">{contentData?.performances?.tableaux[1][0]}</div>
                <div className="head-el-grey">{contentData?.performances?.tableaux[1][1]}</div>
                <div className="head-el-grey">{contentData?.performances?.tableaux[1][2]}</div>
                <div className="head-el-grey">{contentData?.performances?.tableaux[1][3]}</div>
              </div>
              <div className="head-grey-container">
                <div className="head-el">
                  {/* {contentData?.performances?.tableaux[2][1]} */}
                  {isNaN(lastYTD) ? '-' : parseFloat(lastYTD).toFixed(2) + '%'} 
                </div>
                <div className="head-el">
                  {/* {contentData?.performances?.tableaux[2][2]} */}
                  {isNaN(FirstDay) ? '-' : parseFloat(FirstDay).toFixed(3) + '%'}                  
                  </div>
                <div className="head-el">
                  {/* {contentData?.performances?.tableaux[2][3]} */}
                  {isNaN(FirstWeek) ? '-' : parseFloat(FirstWeek).toFixed(2) + '%'}                  
                </div>
                <div className="head-el">
                  {isNaN(FirstMonth) ? '-' : parseFloat(FirstMonth).toFixed(2) + '%'}
                  {/* {contentData?.performances?.tableaux[2][4]} */}
                </div>
              </div>
            </div>
            <div className="performances-annulaisees-group">
              <div className="row-text-annualisee">
                {contentData?.performances?.tableaux[0][1]}
              </div>
              <div className="head-grey-container-1">
                <div className="head-el-blue">
                  {contentData?.performances?.tableaux[1][4]}
                </div>
                <div className="head-el-blue">{contentData?.performances?.tableaux[1][5]}</div>
                <div className="head-el-blue">{contentData?.performances?.tableaux[1][6]}</div>
                <div className="head-el-blue">{contentData?.performances?.tableaux[1][7]}</div>
              </div>
              <div className="head-grey-container">
                <div className="head-el">
                  {/* {contentData?.performances?.tableaux[2][5]} */}
                  {isNaN(TreeMonth) ? '-' : parseFloat(TreeMonth).toFixed(2) + '%'}

                </div>
                <div className="head-el">
                  {/* {contentData?.performances?.tableaux[2][6]} */}
                  {isNaN(SixMonth) ? '-' : parseFloat(SixMonth).toFixed(2) + '%'}

                </div>
                <div className="head-el">
                  {/* {contentData?.performances?.tableaux[2][7]} */}
                  {isNaN(OneYear) ? '-' : parseFloat(OneYear).toFixed(2) + '%'}
                </div>
                <div className="head-el">
                  {/* {contentData?.performances?.tableaux[2][8]} */}
                  {isNaN(TwoYear) ? '-' : parseFloat(TwoYear).toFixed(2) + '%'}
                </div>
              </div>
            </div>
          </div>
          <div style={{ textAlign: 'left', marginBottom: '52px', color: 'grey' }}>
            *Les performances passées ne préjugent pas des performances futures
          </div>
        </div>
      </div>
    </div>
  </>
  );
}
