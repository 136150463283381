import React, { useState, useEffect } from 'react';
import './Text.scoped.scss';
import { bool, object } from 'prop-types';
import { useNavigate } from 'react-router-dom';

TextSection.propTypes = {
  isDesktop: bool,
  contentData: object
};

const accordionData = [
  {
    title: '> Gestion Monétaire et Obligataire Court Terme',
    text: `S’adresse aux clients désirant optimiser la gestion de leurs excédents de trésorerie sur un horizon court allant de quelques jours à 1 an.
    Les Fonds proposés par AFG Asset Management allient performance, liquidité et qualité des actifs détenus.`,
    specificite: `Spécificité des OPCVM proposés par AFG Asset Management : nos OPCVM Monétaires et Obligataires CT excluent tout investissement en titres de dette privée non financière.`,
    imgSrc: '/uploads/image1.jpg',
    link: 'https://afgassetmanagement.com/notre-gamme/2' // Lien pour le premier bouton
  },
  {
    title: '> Gestion Obligataire Moyen et Long Terme',
    text: `S’adresse aux clients désirant capter la performance du marché obligataire marocain tout en investissant dans des supports à liquidité quotidienne et verrouillés en matière de risque de crédit.`,
    imgSrc: '/uploads/image2.png',
    link: 'https://afgassetmanagement.com/notre-gamme/3' // Lien pour le deuxième bouton
  },
  {
    title: '> Gestion Actions et Diversifiés',
    text: `S’adresse aux clients ayant une tolérance au risque Actions et visant un couple performance / risque élevé.`,
    imgSrc: '/uploads/image3.png',
    link: 'https://afgassetmanagement.com/notre-gamme/5' // Lien pour le troisième bouton
  }
];

const AccordionItem = ({ index, title, text, specificite, isOpen, onClick, link }) => {
  return (
    <div className={`accordion-item ${isOpen ? 'open' : ''}`} onClick={() => onClick(index)}>
      <div className={`accordion-title ${isOpen ? 'open-title' : ''}`}>
        <h2>{title}</h2>
      </div>
      {isOpen && (
        <div className="accordion-content">
          <p>{text}</p>
          {specificite && <p className="specificite">{specificite}</p>}
          <button className="en-savoir-plus" onClick={() => window.location.href = link}>
            En savoir plus
          </button>
        </div>
      )}
    </div>
  );
};

const Accordion = ({ data, openIndex, onToggle }) => (
  <div className="accordion">
    {data.map((item, index) => (
      <AccordionItem
        key={index}
        index={index}
        title={item.title}
        text={item.text}
        specificite={item.specificite}
        isOpen={openIndex === index}
        onClick={onToggle}
        link={item.link}
      />
    ))}
  </div>
);

export function TextSection({ isDesktop, contentData }) {
  const [openIndex, setOpenIndex] = useState(null); // Initialisez openIndex à null

  const handleToggle = index => {
    setOpenIndex(prevIndex => (prevIndex === index ? null : index));
  };

  const currentImage = openIndex !== null ? accordionData[openIndex].imgSrc : null;

  return (
    <div
      style={{
        backgroundImage: `url(${process.env.REACT_APP_API_URI}/uploads/ANIMATION_Home_Page_2eabd0fc6e.gif)`,
        backgroundSize: 'cover',
        padding: '20px',
        margin: 0,
        display: 'flex',
        alignItems: 'center'
      }}
      className="section-texte"
    >
      <div className="section-texte-container" style={{ width: '100%' }}>
        <div className="container" style={{ display: 'flex', width: '100%' }}>
        <div className="images-column">
  {currentImage && (
    <img
      src={currentImage}
      alt={`Image ${openIndex + 1}`}
      className={`equal-size-image ${currentImage === accordionData[0].imgSrc ? 'image1-margin-top' : ''}`}
    />
  )}
</div>

          <div className="accordions-column">
            <Accordion data={accordionData} openIndex={openIndex} onToggle={handleToggle} />
          </div>
        </div>
      </div>
    </div>
  );
}
