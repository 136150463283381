import React from 'react';

import './Graph.scoped.scss';
import { bool } from 'prop-types';

Graph.propTypes = {
  isDesktop: bool
};

export function Graph({ isDesktop }) {
  return (
    <>
      <div className="section-graph">
        <div className="section-graph-container">
          <div className="section-graph-titre">Spécificité de nos Fonds Grand Public</div>

          <div className="container-sides-expertise-graph">
            <div className="side-expertise">
              <div className="expertise-elements-container">
                <div className="expertise-element">
                  <div className="logo-expertise-element">
                    <img
                      className="gamme-icone-photo"
                      src={`${process.env.REACT_APP_API_URI}/uploads/money_h_c589a371f4.svg`}
                      alt="logo-blanc"
                    />
                  </div>
                  <div className="text-expertise-element">
                    <strong>Liquidité quotidienne</strong> sur l&apos;ensemble des fonds
                  </div>
                </div>
                {isDesktop && <div className="divider-expertise-element" />}
                <div className="expertise-element">
                  <div className="logo-expertise-element">
                    <img
                      className="gamme-icone-photo"
                      src={`${process.env.REACT_APP_API_URI}/uploads/cadena_43936f1230.svg`}
                      alt="logo-blanc"
                    />
                  </div>
                  <div className="text-expertise-element">
                    Univers d’investissement <strong>verrouillé en risque de crédit</strong>
                  </div>
                </div>
                {isDesktop && <div className="divider-expertise-element" />}
                <div className="expertise-element no-border">
                  <div className="logo-expertise-element">
                    <img
                      className="gamme-icone-photo"
                      src={`${process.env.REACT_APP_API_URI}/uploads/money_v_d1112cf501.svg`}
                      alt="logo-blanc"
                    />
                  </div>
                  <div className="text-expertise-element">
                    Processus d’investissement{' '}
                    <strong>maximisant les leviers de performance</strong>
                  </div>
                </div>
              </div>
            </div>
            <div className="side-graph">
              <img
                className="graph-photo"
                src={`${process.env.REACT_APP_API_URI}/${
                  isDesktop
                    ? 'uploads/graphique_desktop_9c422befc2.png'
                    : 'uploads/graphique_mobile_81a781ffa9.png'
                }`}
                alt="logo-blanc"
              />
            </div>
            <div className="expertise-element-flex">
              <div className="logo-expertise-element-flex">
                <img
                  className="gamme-icone-photo"
                  src={`${process.env.REACT_APP_API_URI}/uploads/pocket_05f9d05a84.svg`}
                  alt="logo-blanc"
                />
              </div>
              <div className="text-expertise-element-flex">
                6 FCP Grand Public couvrant l&apos;ensemble des classes d&apos;actifs, Monétaire,
                Obligataire et Actions
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
