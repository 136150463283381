import React, { useRef, useState, useEffect } from 'react';

import { bool, func, number, string } from 'prop-types';
import { Transition } from 'react-transition-group';

import { fiche } from '../section-4-gamme/StackedCards';
import { getExcelGraphData } from '../../../api/api';

const duration = 200;
const translateWidth = 100;

const defaultStyle = {
  transition: `all ${duration}ms ease-in-out`,
  opacity: 0
};

SlideFadeText.propTypes = {
  text: string,
  inProp: bool,
  setInProp: func,
  direction: string,
  fade: bool, 
  sousTitre2 : string,
  idProduit: string
};

export function SlideFadeText({ inProp, setInProp, text, direction, fade, sousTitre2, lastAN }) {
 
  const formaterChiffre = (valeur) => {
    if(valeur >= 1e9) {
      const chiffre = (valeur / 1e9).toFixed(2);
      return `${chiffre} Mrd DH`;
    }
    else{
      const chiffre = (valeur / 1e6).toFixed(2);
      return `${chiffre} MDH`;
    }
    
  };
  const chiffreFormate = formaterChiffre(lastAN);
  
  
  let transformEntering;
  let transformExiting;
  let transforExited;
  if (!fade) {
    transformEntering =
      direction === 'right'
        ? `translate(-${translateWidth}vw, 0vh)`
        : `translate(${translateWidth}vw, 0vh)`;
    transformExiting =
      direction === 'right'
        ? `translate(${translateWidth}vw, 0vh)`
        : `translate(-${translateWidth}vw, 0vh)`;
    transforExited =
      direction === 'right'
        ? `translate(${translateWidth}vw, 0vh)`
        : `translate(${translateWidth}vw, 0vh)`;
  }
  const transitionStyles = {
    entered: { opacity: 1 },
    entering: { opacity: 1, transform: transformEntering },
    exiting: {
      opacity: 0,
      transform: transformExiting,
      transitionDuration: '0.2s'
    },
    exited: { opacity: 0, transform: transforExited }
  };

  const nodeRef = useRef(null);
  const setInPropAndLog = (boolean) => {
    setInProp(boolean);
  };
  return (
    <>
      <Transition
        onExited={() => setInPropAndLog(true)}
        nodeRef={nodeRef}
        in={inProp}
        timeout={duration}>
        {(state) => (
          <div
            ref={nodeRef}
            style={{
              ...defaultStyle,
              ...transitionStyles[state]
            }}>
            {text} 
            {sousTitre2 === true ? ` =  ${chiffreFormate} ` : ""}
          </div>
        )}
      </Transition>
    </>
  );
}
