import React, { useEffect, useState } from 'react';

import './MentionsLegales.scoped.scss';
import { getMentionsLegales } from '../../api/api';

export function MentionsLegales() {
  document.body.classList.remove('hidden-scrollbar-y');
  const [hasDataBeenFetched, setHasDataBeenFetched] = useState(false);
  const [contentData, setContentData] = useState(null);
  useEffect(() => {
    if (!hasDataBeenFetched) {
      getMentionsLegales().then((data) => {
        setContentData(data?.data);
        setHasDataBeenFetched(true);
      });
    }
  }, [contentData]);
  return (
    <>
      <div className="section-mentions-legales">
        <div className="section-mentions-legales-container">
          <div className="section-mentions-legales-container-bis">
            <div className="mentions-legales-title">{contentData?.titre}</div>
            <br />
            <br />
            <div className="mentions-legales-sub-title">{contentData?.titre1}</div>
            <br />
            <br />
            <div className="mentions-legales-paragraphe">{contentData?.paragraphe1_1}</div>
            <br />
            <div className="mentions-legales-paragraphe">{contentData?.paragraphe1_2}</div>
            <br />
            <div className="mentions-legales-paragraphe">{contentData?.paragraphe1_3}</div>
            <br />
            <br />
            <div className="mentions-legales-sub-title">{contentData?.titre2}</div>
            <br />
            <br />
            <div className="mentions-legales-paragraphe">{contentData?.paragraphe2_1}</div>
            <br />
            <div className="mentions-legales-paragraphe">{contentData?.paragraphe2_2}</div>
            <br />
            <div className="mentions-legales-paragraphe">{contentData?.paragraphe2_3}</div>
            <br />
            <div className="mentions-legales-sub-title">{contentData?.titre3}</div>
            <br />
            <br />
            <div className="mentions-legales-paragraphe">{contentData?.paragraphe3_1}</div>
            <br />
            {contentData?.paragraphe3_list?.map((line, index) => (
              <>
                <div key={index} className="mentions-legales-paragraphe">
                  {line}
                </div>
                <br />
              </>
            ))}

            <div className="mentions-legales-paragraphe">{contentData?.paragraphe3_2}</div>
            <br />
            <div className="mentions-legales-paragraphe">
              {contentData?.paragraphe3_3} <strong>{contentData?.contactEmail}</strong>.
            </div>
            <br />
            <div className="mentions-legales-paragraphe">{contentData?.paragraphe3_4}</div>

            <br />
            <br />
            <div className="mentions-legales-sub-title">{contentData?.titre4}</div>
            <br />
            <br />
            <div className="mentions-legales-paragraphe">{contentData?.paragraphe4_1}</div>
            <br />
            <br />
            <div className="mentions-legales-sub-title">{contentData?.titre5}</div>
            <br />
            <br />
            <div className="mentions-legales-paragraphe">{contentData?.paragraphe5_1}</div>
            <br />
            <div className="mentions-legales-paragraphe">{contentData?.paragraphe5_2}</div>
            <br />
            <div className="mentions-legales-paragraphe">{contentData?.paragraphe5_3}</div>
            <br />
            {contentData?.paragraphe5_list?.map((line, index) => (
              <>
                <div key={index} className="mentions-legales-paragraphe">
                  {line}
                </div>
                <br />
              </>
            ))}
            <div className="mentions-legales-paragraphe">{contentData?.paragraphe5_4}</div>
            <br />
            <div className="mentions-legales-paragraphe">{contentData?.paragraphe5_5}</div>
            <br />
            <div className="mentions-legales-paragraphe">{contentData?.paragraphe5_6}</div>
            <br />
            <div className="mentions-legales-paragraphe">{contentData?.paragraphe5_7}</div>
            <br />
            <div className="mentions-legales-paragraphe">
              Selon votre navigateur, vous disposez des options suivantes : accepter ou rejeter les
              cookies de toute origine ou d’une provenance donnée.
            </div>
            <br />
            <div className="mentions-legales-paragraphe">{contentData?.paragraphe5_8}</div>
          </div>
        </div>
      </div>
    </>
  );
}
