/* eslint-disable react/display-name */
// Section.jsx

import React from 'react';
import { array, string } from 'prop-types';

export const SectionBis = React.forwardRef(({ id, children }, ref) => (
  <section className="bis" ref={ref} id={id}>
    {children}
  </section>
));

SectionBis.propTypes = {
  id: string,
  children: array
};

export const SectionBis2 = React.forwardRef(({ id, children }, ref) => (
  <section className="bis2" ref={ref} id={id}>
    {children}
  </section>
));

SectionBis2.propTypes = {
  id: string,
  children: array
};

export const SectionBis3 = React.forwardRef(({ id, children }, ref) => (
  <section className="bis3" ref={ref} id={id}>
    {children}
  </section>
));

SectionBis3.propTypes = {
  id: string,
  children: array
};

export const SectionBis4 = React.forwardRef(({ id, children }, ref) => (
  <section className="bis4" ref={ref} id={id}>
    {children}
  </section>
));

SectionBis4.propTypes = {
  id: string,
  children: array
};

export const SectionBis5 = React.forwardRef(({ id, children }, ref) => (
  <section className="bis5" ref={ref} id={id}>
    {children}
  </section>
));

SectionBis5.propTypes = {
  id: string,
  children: array
};
