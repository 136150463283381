import React, { useEffect, useState } from 'react';

import './NosPublications.scoped.scss';
import { bool, func } from 'prop-types';
import { PdfItem } from './PdfItem';
import { getPublications } from '../../api/api';

NosPublications.propTypes = {
  isDesktop: bool,
  isAuthenticated: bool,
  handleLogout: func
};
export function NosPublications({ isDesktop, isAuthenticated }) {
  document.body.classList.remove('hidden-scrollbar-y');
  const [hasDataBeenFetched, setHasDataBeenFetched] = useState(false);
  const [contentData, setContentData] = useState(null);
  useEffect(() => {
    if (!hasDataBeenFetched) {
      getPublications().then((data) => {
        setContentData(data);
        setHasDataBeenFetched(true);
      });
    }
  }, [contentData]);
  return (
    <>
      <div className="nos-publications-page">
        <div
          style={{
            backgroundImage: `url(${process.env.REACT_APP_API_URI}/uploads/gif1_97ad8d872e.gif)`,
            backgroundSize: 'cover',
            backgroundPositionX: 'center'
          }}
          className="nos-publications-titre-principal">
          <div className="nos-publications-titre-principal-text">{contentData?.titre}</div>
        </div>
        <div className="nos-publications-docs-background">
          <div className="docs-pdf">
            {contentData?.publications
              ?.sort((a, b) => -a?.id + b?.id)
              .map((doc, index) => (
                <PdfItem
                  isDesktop={isDesktop}
                  subTitle={doc.name}
                  key={index}
                  isAuthenticated={isAuthenticated}
                  url={doc.url}
                />
              ))}
          </div>
        </div>
      </div>
    </>
  );
}
