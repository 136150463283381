import React from 'react';

import './MobilePortrait.scoped.scss';

export function MobilePortrait() {
  return (
    <div className="mobile-landscape-container">
      <div className="mobile-landscape-img-container">
        <img src={`${process.env.REACT_APP_API_URI}/uploads/Paysage_76b0a55223.png`} />
      </div>
      <div className="mobile-landscape-text-1">VEUILLEZ PIVOTER VOTRE APPAREIL</div>
      <div className="mobile-landscape-text-2">
        Pour une meilleure expérience, veuillez revenir au mode portrait.
      </div>
    </div>
  );
}
