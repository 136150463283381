import React, { useEffect, useMemo, useState } from 'react';

import { bool, object, string } from 'prop-types';
import AppsIcon from '@mui/icons-material/Apps';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import './FcpManagementTitre.scoped.scss';
import { useNavigate } from 'react-router-dom';

export function useIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () => new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting)),
    []
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}

FcpManagementTitre.propTypes = {
  isInViewPortTitlePrincipal: bool,
  contentData: object,
  idPage: string,
  isDesktop: bool
};
const TRANSITION_DURATION = 1;

export function FcpManagementTitre({ isInViewPortTitlePrincipal, contentData, idPage, isDesktop }) {
  const navigate = useNavigate();
  const handleClickMenu = () => navigate('/nos-fonds');
  const handleMoveForward = () =>
    navigate('/notre-gamme/' + (Number(idPage) + 1 <= 7 ? Number(idPage) + 1 : 1));
  const handleMoveBackward = () =>
    navigate('/notre-gamme/' + (Number(idPage) - 1 >= 1 ? Number(idPage) - 1 : 7));
  return (
    <>
      {isDesktop ? (
        <div className="section-3-fcp-management">
          <div className="section-titre-2-container">
            <div className="section-titre-fcp-management">
              <div
                style={{
                  transform: isInViewPortTitlePrincipal
                    ? 'translate(0vw, 10vh)'
                    : 'translate(0vw, 18vh)',
                  transitionDuration: `${TRANSITION_DURATION}s`
                }}
                className="section-titre-fcp-management-text">
                <div>{contentData?.titrePrincipal}</div>
                <div>
                  <div className="page-afg-buttons">
                    <div className="afg-container-icone-navigation">
                      <AppsIcon
                        onClick={() => handleClickMenu()}
                        sx={{ color: '#FFFFFF', cursor: 'pointer' }}
                      />
                    </div>
                    <div className="container-icone-navigation">
                      <ArrowForwardIosIcon
                        onClick={handleMoveForward}
                        sx={{ color: '#FFFFFF', cursor: 'pointer' }}
                      />
                    </div>
                    <div className="container-icone-navigation">
                      <ArrowBackIosNewIcon
                        onClick={handleMoveBackward}
                        sx={{ color: '#FFFFFF', cursor: 'pointer' }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                transform: isInViewPortTitlePrincipal
                  ? 'translate(0vw, 0vh)'
                  : 'translate(0vw, -2vh)',
                transitionDuration: `${TRANSITION_DURATION}s`
              }}>
              <div className="fcp-management-divider" />
              <div className="fcp-management-paragraph">{contentData?.paragraphePrincipal}</div>
            </div>
          </div>
        </div>
      ) : (
        <div className="page-afg-buttons-mobile">
          <div className="afg-container-icone-navigation">
            <ArrowBackIosNewIcon
              onClick={handleMoveBackward}
              sx={{
                color: '#FFFFFF',
                cursor: 'pointer',
                height: '56px',
                width: '56px',
                zIndex: 10
              }}
            />
          </div>
          <div className="afg-container-icone-navigation-mobile">
            <AppsIcon
              onClick={handleClickMenu}
              sx={{
                color: '#FFFFFF',
                cursor: 'pointer',
                height: '56px',
                width: '56px',
                zIndex: 10
              }}
            />
          </div>
          <div className="afg-container-icone-navigation">
            <ArrowForwardIosIcon
              onClick={handleMoveForward}
              sx={{
                color: '#FFFFFF',
                cursor: 'pointer',
                height: '56px',
                width: '56px',
                zIndex: 10
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}
